import { StatsWrapper, StyledBg } from '../../style/projectComponents'
import { StatisticsComponent } from '../../components/DashboardComponents/StatisticsComponent'
import toLocaleNumber from '../../utils/toLocaleNumber'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AdminAnalyticsBase } from '../../components/AdminComponents/AdminAnalytics/AdminAnalyticsBase'
import {
  useGetApiStatisticGetBalancesStatistic,
  useGetApiStatisticGetDepositsStatistic,
  useGetApiStatisticGetInvestPoolsStatistic,
  useGetApiStatisticGetSystemBalancesStatistic,
  useGetApiStatisticGetUsersStatistic,
} from '../../service/api/generated'
import PageWrapper from '../../components/global/PageWrapper'

export const AdminAnalytics = () => {
  const { t } = useTranslation()

  const { data: balanceStats, isLoading: isBalancesStatsLoading } =
    useGetApiStatisticGetBalancesStatistic()
  const { data: systemBalancesStats, isLoading: isSystemBalancesLoading } =
    useGetApiStatisticGetSystemBalancesStatistic()
  const { data: investPoolsStats } = useGetApiStatisticGetInvestPoolsStatistic()
  const { data: depositsStats, isLoading: isDepositsStatsLoading } =
    useGetApiStatisticGetDepositsStatistic()
  const { data: usersStats, isLoading: isUsersStatsLoading } = useGetApiStatisticGetUsersStatistic()

  const totalBalance = systemBalancesStats?.data?.totalWalletsBalanceInUsd || 0
  const currentInvestPoolsIncome = investPoolsStats?.data?.currentAllInvestPoolsIncomeInUsd || 0

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageWrapper>
      <StyledBg>
        <StatsWrapper>
          <StatisticsComponent
            title={usersStats?.data?.totalUsersCount?.toString() || '0'}
            description={t('adminAnalytics.totalUsers')}
          />
          <StatisticsComponent
            title={`${toLocaleNumber(totalBalance)}`}
            symbol="USD"
            description={t('adminAnalytics.totalDeposits')}
          />
          <StatisticsComponent
            title={`${toLocaleNumber(currentInvestPoolsIncome)}`}
            symbol="USD"
            description={t('adminAnalytics.currentInvestPoolsIncome')}
          />
        </StatsWrapper>
        <AdminAnalyticsBase
          isUsersStatsLoading={isUsersStatsLoading}
          isDepositsStatsLoading={isDepositsStatsLoading}
          isBalancesStatsLoading={isBalancesStatsLoading}
          isSystemBalancesLoading={isSystemBalancesLoading}
          usersStatsData={usersStats?.data}
          depositsStatsData={depositsStats?.data}
          balanceStatsData={balanceStats?.data}
          systemBalancesStatisticsData={systemBalancesStats?.data}
        />
      </StyledBg>
    </PageWrapper>
  )
}
