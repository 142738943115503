import { useTheme } from 'styled-components/macro'

export const DashboardIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.41602 14.5833V12.0833M9.58268 14.5833V7.08333M13.7493 14.5833V11.25M17.916 4.58333C17.916 5.24637 17.6526 5.88225 17.1838 6.3511C16.7149 6.81994 16.0791 7.08333 15.416 7.08333C14.753 7.08333 14.1171 6.81994 13.6482 6.3511C13.1794 5.88225 12.916 5.24637 12.916 4.58333C12.916 3.92029 13.1794 3.2844 13.6482 2.81556C14.1171 2.34672 14.753 2.08333 15.416 2.08333C16.0791 2.08333 16.7149 2.34672 17.1838 2.81556C17.6526 3.2844 17.916 3.92029 17.916 4.58333Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.41602 14.5833V12.0833M9.58268 14.5833V7.08333M13.7493 14.5833V11.25M17.916 4.58333C17.916 5.24637 17.6526 5.88225 17.1838 6.3511C16.7149 6.81994 16.0791 7.08333 15.416 7.08333C14.753 7.08333 14.1171 6.81994 13.6482 6.3511C13.1794 5.88225 12.916 5.24637 12.916 4.58333C12.916 3.92029 13.1794 3.2844 13.6482 2.81556C14.1171 2.34672 14.753 2.08333 15.416 2.08333C16.0791 2.08333 16.7149 2.34672 17.1838 2.81556C17.6526 3.2844 17.916 3.92029 17.916 4.58333Z"
        stroke="url(#paint0_linear_2266_3900)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.913 9.16666C17.913 9.16666 17.9163 9.44999 17.9163 9.99999C17.9163 13.7317 17.9163 15.5983 16.7572 16.7575C15.598 17.9167 13.7322 17.9167 9.99967 17.9167C6.26801 17.9167 4.40134 17.9167 3.24217 16.7575C2.08301 15.5983 2.08301 13.7325 2.08301 9.99999C2.08301 6.26833 2.08301 4.40249 3.24217 3.24249C4.40134 2.08333 6.26717 2.08333 9.99967 2.08333H10.833"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.913 9.16666C17.913 9.16666 17.9163 9.44999 17.9163 9.99999C17.9163 13.7317 17.9163 15.5983 16.7572 16.7575C15.598 17.9167 13.7322 17.9167 9.99967 17.9167C6.26801 17.9167 4.40134 17.9167 3.24217 16.7575C2.08301 15.5983 2.08301 13.7325 2.08301 9.99999C2.08301 6.26833 2.08301 4.40249 3.24217 3.24249C4.40134 2.08333 6.26717 2.08333 9.99967 2.08333H10.833"
        stroke="url(#paint1_linear_2266_3900)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2266_3900"
          x1="-8.92222"
          y1="-58.4635"
          x2="4.10786"
          y2="-59.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2266_3900"
          x1="-16.0788"
          y1="-74.6094"
          x2="0.426008"
          y2="-76.1261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
