import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FlexRowBetween, FlexRow, FlexColumn, FlexColumnStart } from '../../style/projectComponents'
import { useTranslation } from 'react-i18next'

const Block = styled(FlexColumn)`
  width: 100%;
  border-radius: 16px;
  /* border: 1px solid #8152b1; // TODO: gradient border */
  background:
    linear-gradient(#0a0a0a, #0a0a0a) padding-box,
    linear-gradient(to right, #000000 0%, #8152b1 102.04%) border-box;
  border: 1px solid transparent;
  padding: 16px 20px;
`

const Title = styled.h2`
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: 600;
`

const OpenContentButton = styled(FlexRow)`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 10px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
    filter: ${({ theme }) => theme.colors.black === '#fff' && 'invert(1)'};
  }
`
const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 600;
`
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
`

interface FaqDropdownProps {
  title: string
  content: { subtitle: string; text: string }[]
}

export const FaqDropdown: React.FC<FaqDropdownProps> = ({ title, content }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Block>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <Title>{title}</Title>
        <OpenContentButton onClick={() => setIsOpen(b => !b)}>
          <img
            src="/images/buttons/arrow-down.svg"
            alt="arrow-open-text-button"
            style={{ transform: isOpen && 'rotate(180deg)' }}
          />
        </OpenContentButton>
      </FlexRowBetween>
      {isOpen && (
        <FlexColumnStart style={{ width: '100%', marginTop: 18 }}>
          {content?.map(item => (
            <FlexColumnStart>
              <Subtitle>{item?.subtitle}</Subtitle>
              <Text>{item?.text}</Text>
            </FlexColumnStart>
          ))}
        </FlexColumnStart>
      )}
    </Block>
  )
}
