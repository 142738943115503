import { useTheme } from 'styled-components/macro'

export const CommissionsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4375 11.25V6.25M10 6.25V5M10 12.5V11.25M8.4375 8.75H11.5625M11.5625 8.75C12.08 8.75 12.5 9.17 12.5 9.6875V10.3125C12.5 10.83 12.08 11.25 11.5625 11.25H7.5M11.5625 8.75C12.08 8.75 12.5 8.33 12.5 7.8125V7.1875C12.5 6.67 12.08 6.25 11.5625 6.25H7.5"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.4375 11.25V6.25M10 6.25V5M10 12.5V11.25M8.4375 8.75H11.5625M11.5625 8.75C12.08 8.75 12.5 9.17 12.5 9.6875V10.3125C12.5 10.83 12.08 11.25 11.5625 11.25H7.5M11.5625 8.75C12.08 8.75 12.5 8.33 12.5 7.8125V7.1875C12.5 6.67 12.08 6.25 11.5625 6.25H7.5"
        stroke="url(#paint0_linear_2418_9948)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.11658 17.9267L7.52492 16.9225C7.11992 16.6675 6.91825 16.5408 6.69409 16.5333C6.45159 16.525 6.24575 16.6475 5.80909 16.9225C5.31992 17.2308 4.34742 18.0808 3.71742 17.6758C3.33325 17.4275 3.33325 16.7983 3.33325 15.5383V6.66667C3.33325 4.31 3.33325 3.13083 4.02242 2.39917C4.71159 1.6675 5.82075 1.66667 8.03909 1.66667H11.9608C14.1791 1.66667 15.2883 1.66667 15.9774 2.39917C16.6666 3.13083 16.6666 4.31 16.6666 6.66667V15.5383C16.6666 16.7975 16.6666 17.4275 16.2816 17.6758C15.6524 18.0808 14.6799 17.2308 14.1908 16.9225C13.7866 16.6675 13.5849 16.5408 13.3599 16.5333C13.1183 16.525 12.9124 16.6475 12.4758 16.9225L10.8833 17.9267C10.4533 18.1975 10.2391 18.3333 9.99992 18.3333C9.76075 18.3333 9.54575 18.1975 9.11658 17.9267Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.11658 17.9267L7.52492 16.9225C7.11992 16.6675 6.91825 16.5408 6.69409 16.5333C6.45159 16.525 6.24575 16.6475 5.80909 16.9225C5.31992 17.2308 4.34742 18.0808 3.71742 17.6758C3.33325 17.4275 3.33325 16.7983 3.33325 15.5383V6.66667C3.33325 4.31 3.33325 3.13083 4.02242 2.39917C4.71159 1.6675 5.82075 1.66667 8.03909 1.66667H11.9608C14.1791 1.66667 15.2883 1.66667 15.9774 2.39917C16.6666 3.13083 16.6666 4.31 16.6666 6.66667V15.5383C16.6666 16.7975 16.6666 17.4275 16.2816 17.6758C15.6524 18.0808 14.6799 17.2308 14.1908 16.9225C13.7866 16.6675 13.5849 16.5408 13.3599 16.5333C13.1183 16.525 12.9124 16.6475 12.4758 16.9225L10.8833 17.9267C10.4533 18.1975 10.2391 18.3333 9.99992 18.3333C9.76075 18.3333 9.54575 18.1975 9.11658 17.9267Z"
        stroke="url(#paint1_linear_2418_9948)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9948"
          x1="1.76471"
          y1="-31.3281"
          x2="7.0011"
          y2="-31.6489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2418_9948"
          x1="-11.9609"
          y1="-79.0625"
          x2="1.97991"
          y2="-80.0874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
