import React from 'react'
import {
  FlexRow,
  FlexColumnStart,
  FlexRowBetween,
  StyledButton,
} from '../../style/projectComponents'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'

const Block = styled(FlexRowBetween)`
  padding: 10px 15px;
  width: 100%;
  max-width: 1000px;
  background: ${({ theme }) => theme.colors.gray050};
  margin-bottom: 30px;
  border-radius: 9px;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`
const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  @media (max-width: 800px) {
    margin-bottom: 20px;
  }
`
const ResponsiveRow = styled(FlexRow)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const InvitePartnersBlock: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Block>
      <ResponsiveRow>
        <img
          src="/images/interface/users.png"
          alt=""
          style={{ marginRight: 10, width: 55, height: 55 }}
        />
        <FlexColumnStart>
          <Title>Bonus for inviting partners to your team</Title>
          <Description>Send your referral link to your partner...</Description>
        </FlexColumnStart>
      </ResponsiveRow>
      <StyledButton variant="primary" onClick={() => navigate('/referrals')}>
        Invite a partner
      </StyledButton>
    </Block>
  )
}
