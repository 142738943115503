import { PROJECT_NAME } from '../../config/serverConstants'
import { DashboardIcon } from '../../assets/svgIcons/DashboardIcon'
import { AssetsSvgIcon } from '../../assets/svgIcons/AssetsSvgIcon'
import { MarketplaceSvgIcon } from '../../assets/svgIcons/MarketplaceSvgIcon'
import { ReferralsSvgIcon } from '../../assets/svgIcons/ReferralsSvgIcon'
import { SettingsSvgIcon } from '../../assets/svgIcons/SettingsSvgIcon'
import { UserListIcon } from '../../assets/svgIcons/UserListIcon'
import { WithdrawalRequestsSvgIcon } from '../../assets/svgIcons/WithdrawalRequestsSvgIcon'
import { WithdrawalHistorySvgIcon } from '../../assets/svgIcons/WithdrawalHistorySvgIcon'
import { HistoryOperationsSvgIcon } from '../../assets/svgIcons/HistoryOperationsSvgIcon'
import { InvestPoolsSvgIcon } from '../../assets/svgIcons/InvestPoolsSvgIcon'
import { WalletsSvgIcon } from '../../assets/svgIcons/WalletsSvgIcon'
import { CommissionsSvgIcon } from '../../assets/svgIcons/CommissionsSvgIcon'
import { CurrenciesSvgIcon } from '../../assets/svgIcons/CurrenciesSvgIcon'
import { ConvertSvgIcon } from '../../assets/svgIcons/ConvertSvgIcon'

export const headerAuthLinks = [
  {
    to: '/dashboard',
    text: 'menu.dashboard',
    icon: DashboardIcon,
  },
  {
    to: '/marketplace',
    text: 'menu.marketplace',
    icon: MarketplaceSvgIcon,
  },
  {
    to: '/assets',
    text: 'menu.deposit',
    icon: AssetsSvgIcon,
  },
  {
    to: '/trade-convert',
    text: 'menu.convert',
    icon: ConvertSvgIcon,
  },
  PROJECT_NAME === 'Def' && {
    to: '/markets/BTCUSDT',
    text: 'menu.markets',
    icon: CommissionsSvgIcon,
  },
  {
    to: '/referrals',
    text: 'menu.referrals',
    icon: ReferralsSvgIcon,
  },
  {
    to: '/settings',
    text: 'menu.settings',
    icon: SettingsSvgIcon,
  },
]

export const headerNotAuthLinks = [
  {
    to: 'profit',
    text: 'menu.profit',
  },
  {
    to: 'principles',
    text: 'menu.principles',
  },
  {
    to: 'plans',
    text: 'menu.plans',
  },
  {
    to: 'cooperation',
    text: 'menu.cooperation',
  },
]

export const adminLinks = [
  {
    to: '/admin/analytics',
    text: 'menu.dashboard',
    icon: DashboardIcon,
  },
  {
    to: '/admin/users',
    text: 'menu.usersList',
    icon: UserListIcon,
  },
  {
    to: '/admin/withdraw',
    text: 'menu.withdrawRequests',
    icon: WithdrawalRequestsSvgIcon,
  },
  {
    to: '/admin/withdraw-history',
    text: 'menu.withdrawHistory',
    icon: WithdrawalHistorySvgIcon,
  },
  {
    to: '/admin/operations',
    text: 'menu.historyOperations',
    icon: HistoryOperationsSvgIcon,
  },
  {
    to: '/admin/invest-pools',
    text: 'Invest Pools',
    icon: InvestPoolsSvgIcon,
  },
  {
    to: '/admin/currencies',
    text: 'Currencies',
    icon: CurrenciesSvgIcon,
  },
  {
    to: '/admin/wallets',
    text: 'Wallets',
    icon: WalletsSvgIcon,
  },
  {
    to: '/admin/commissions',
    text: 'Commissions',
    icon: CommissionsSvgIcon,
  },
]
