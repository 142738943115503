import {
  FlexColumnStart,
  FlexRow,
  StyledButton,
  FlexRowBetween,
  MarketTextBold,
  MarketText,
  InfoText,
  ErrorText,
  TradeLabel,
} from '../../../style/projectComponents'
import styled, { useTheme } from 'styled-components/macro'
import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { isAmountGood } from '../../WithdrawComponents/utils'
import { ValidationInputComponentMax } from '../ValidationInputComponentMax'
import { IInvestPool, IWithdrawPoolReq } from '../../../service/investpool-api/models'
import { useMutation, useQuery } from 'react-query'
import InvestPoolApi from '../../../service/investpool-api/InvestPoolApi'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import SelectCurrencyPanel from '../SelectCurrencyPanel'
import AdminApi from '../../../service/admin-api/AdminApi'
import { getInvestPoolContent } from '../../../utils/getInvestPoolContent'
import { InvestPoolDto } from '../../../service/api/generated'

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;

  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: ${({ theme }) => theme.colors.modalBg};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 13000;
`

const Content = styled(FlexColumnStart)`
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: var(--8, 0.5rem);
  padding: 20px 25px;
  position: relative;
  width: 480px;
  align-items: center;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 0.5rem;
  margin-top: 16px;
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
  }
`

const CloseButton = styled(StyledButton)`
  align-self: end;
  padding: 0;
`
const InvestLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 8px;
`

interface WithdrawFromInvestModalProps {
  setIsOpen: (bool) => void
  setInvestOpen: (bool) => void
  investPool: InvestPoolDto
}

const WithdrawFromInvestModal: FC<WithdrawFromInvestModalProps> = ({
  setIsOpen,
  setInvestOpen,
  investPool,
}) => {
  const { t } = useTranslation()

  const {
    register,
    control,
    clearErrors,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
  } = useForm()

  const [currencyId, setCurrencyId] = useState(0)
  const changeCurrencyId = (id: number) => setCurrencyId(id)

  const investPoolTitles = [
    t('invest.totalInvested'),
    t('invest.yourLimit'),
    t('invest.yourInvestments'),
    t('invest.table.tokens'),
    t('invest.table.period'),
    t('invest.table.term'),
    t('invest.holdPeriod'),
  ]
  const investPoolContent = getInvestPoolContent(investPool, investPoolTitles)

  const logo = investPool.logoDownloadLink

  const GlobalModalStorage = useContext(GlobalModalStore)
  const withdrawMutation = useMutation((data: IWithdrawPoolReq) =>
    InvestPoolApi.withdrawalFromInvestmentPool(data)
  )

  const [currencyList, setCurrencyList] = useState(null)
  const currencyItems = currencyList?.currencies || []
  const tokenId = currencyItems.find(c => c.name === investPool.availableCurrencies[currencyId])?.id
  const currentWallet = investPool?.userWallets?.find(uw => uw.currencyId === tokenId)
  const userDeposit = currentWallet?.availableBalance

  const setValueToMax = () => {
    if (userDeposit > 0) {
      setValue('amount', userDeposit)
    }
  }

  const GetCurrenciesList = useQuery(
    'currency_list',
    () =>
      AdminApi.getCurrencies({
        skip: 0,
        take: 30,
      }),
    {
      onSuccess: data => {
        setCurrencyList(data)
      },
    }
  )

  const onSubmit = data => {
    const investReq = {
      investPoolId: investPool.id,
      value: parseFloat(data.amount),
      currencyId: tokenId,
    }
    withdrawMutation.mutate(investReq, {
      onSuccess: data => {
        GlobalModalStorage.makeVisible(true, t('common.success'))
        reset({
          amount: '',
        })
        setIsOpen(false)
      },
      onError: error => {
        GlobalModalStorage.makeVisible(false, t('common.error'))
      },
    })
  }

  const theme = useTheme()

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setIsOpen(false)
        }
      }}
    >
      <Content>
        <FlexRowBetween
          style={{
            alignItems: 'center',
            borderBottom: `1px solid $${({ theme }) => theme.colors.lightGray}`,
            paddingBottom: 12,
          }}
        >
          <FlexRow>
            <InvestLogo src={logo} alt="" />
            <MarketTextBold>{investPool.name}</MarketTextBold>
          </FlexRow>
          <CloseButton onClick={() => setIsOpen(false)} variant={'text'}>
            <img src={'/images/ic_cancel.svg'} alt="close" />
          </CloseButton>
        </FlexRowBetween>

        <InnerWrapper>
          {investPoolContent.map((i, key) => (
            <FlexRowBetween key={i.title}>
              <MarketText>{i.title}</MarketText>
              <MarketTextBold>{i.value}</MarketTextBold>
            </FlexRowBetween>
          ))}

          <FlexColumnStart style={{ width: '100%', marginTop: 16 }}>
            <SelectCurrencyPanel
              elements={investPool.availableCurrencies}
              selectedId={currencyId}
              selectElement={changeCurrencyId}
            />
            <InputWrapper style={{ marginBottom: 5, marginTop: 16 }}>
              <Controller
                name="amount"
                control={control}
                rules={isAmountGood(userDeposit)}
                render={({ field: { ref, ...field } }) => (
                  <ValidationInputComponentMax
                    setValueToMax={setValueToMax}
                    {...field}
                    type="text"
                    isError={!!errors['amount']}
                    label={t('withdraw.amount')}
                  />
                )}
              />
            </InputWrapper>

            <InfoText style={{ marginTop: 3, marginBottom: 3 }} onClick={setValueToMax}>
              {t('withdraw.available')}:{' '}
              <span style={{ color: `${({ theme }) => theme.colors.secondary}` }}>
                {userDeposit?.toFixed(6) || 0} USDT
              </span>
            </InfoText>

            <ErrorText hasError={!!errors['amount']}>
              {t(errors['amount']?.message?.toString())}
            </ErrorText>
          </FlexColumnStart>

          <StyledButton
            onClick={() => clearErrors()}
            variant="black"
            type="submit"
            style={{ width: '100%', height: 44, marginTop: 12 }}
          >
            {t('withdraw.withdraw')}
          </StyledButton>
          <StyledButton
            variant="text"
            noRadius
            onClick={() => {
              setInvestOpen(true)
              setIsOpen(false)
            }}
            type="button"
            style={{ width: '100%', color: theme.colors.black }}
          >
            {t('invest.invest')}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  )
}

export default WithdrawFromInvestModal
