import { useTheme } from 'styled-components/macro'

export const InvestPoolsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3334 17.5H17.5001C16.2909 17.5 15.2317 16.7841 15.0001 15.8333C14.7684 16.7841 13.7092 17.5 12.5001 17.5C11.2909 17.5 10.2317 16.7841 10.0001 15.8333C9.76841 16.7841 8.70925 17.5 7.50008 17.5C6.29091 17.5 5.23175 16.7841 5.00008 15.8333C4.76841 16.7841 3.70925 17.5 2.50008 17.5H1.66675M15.8334 2.49996L15.6126 2.5733C14.5126 2.93996 13.9626 3.1233 13.6484 3.55996C13.3342 3.99663 13.3334 4.57663 13.3334 5.7358V14.1666M9.16675 2.49996L8.94591 2.5733C7.84591 2.93996 7.29591 3.1233 6.98175 3.55996C6.66758 3.99663 6.66675 4.57663 6.66675 5.7358V14.1666M6.66675 5.8333H13.3334M6.66675 9.16663H13.3334M6.66675 12.5H13.3334"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3334 17.5H17.5001C16.2909 17.5 15.2317 16.7841 15.0001 15.8333C14.7684 16.7841 13.7092 17.5 12.5001 17.5C11.2909 17.5 10.2317 16.7841 10.0001 15.8333C9.76841 16.7841 8.70925 17.5 7.50008 17.5C6.29091 17.5 5.23175 16.7841 5.00008 15.8333C4.76841 16.7841 3.70925 17.5 2.50008 17.5H1.66675M15.8334 2.49996L15.6126 2.5733C14.5126 2.93996 13.9626 3.1233 13.6484 3.55996C13.3342 3.99663 13.3334 4.57663 13.3334 5.7358V14.1666M9.16675 2.49996L8.94591 2.5733C7.84591 2.93996 7.29591 3.1233 6.98175 3.55996C6.66758 3.99663 6.66675 4.57663 6.66675 5.7358V14.1666M6.66675 5.8333H13.3334M6.66675 9.16663H13.3334M6.66675 12.5H13.3334"
        stroke="url(#paint0_linear_2418_9939)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9939"
          x1="-17.4509"
          y1="-70.1563"
          x2="-0.111523"
          y2="-71.9268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
