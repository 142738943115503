import { useTheme } from 'styled-components/macro'

export const CurrenciesSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1672 16.8325C12.4047 16.8325 13.5916 16.3408 14.4667 15.4658C15.3418 14.5907 15.8334 13.4038 15.8334 12.1662C15.8334 10.9287 15.3418 9.74182 14.4667 8.86674C13.5916 7.99166 12.4047 7.50004 11.1672 7.50004C9.92963 7.50004 8.74276 7.99166 7.86768 8.86674C6.99259 9.74182 6.50098 10.9287 6.50098 12.1662C6.50098 13.4038 6.99259 14.5907 7.86768 15.4658C8.74276 16.3408 9.92963 16.8325 11.1672 16.8325Z"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1672 16.8325C12.4047 16.8325 13.5916 16.3408 14.4667 15.4658C15.3418 14.5907 15.8334 13.4038 15.8334 12.1662C15.8334 10.9287 15.3418 9.74182 14.4667 8.86674C13.5916 7.99166 12.4047 7.50004 11.1672 7.50004C9.92963 7.50004 8.74276 7.99166 7.86768 8.86674C6.99259 9.74182 6.50098 10.9287 6.50098 12.1662C6.50098 13.4038 6.99259 14.5907 7.86768 15.4658C8.74276 16.3408 9.92963 16.8325 11.1672 16.8325Z"
        stroke="url(#paint0_linear_2418_9933)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9169 13.6198C12.6381 14.147 12.1301 14.4993 11.5503 14.4993C10.6695 14.4993 9.9556 13.6868 9.9556 12.6848V11.6471C9.9556 10.6456 10.6695 9.83314 11.5503 9.83314C12.1301 9.83314 12.6381 10.1854 12.9169 10.7127M9.41724 12.1662H11.7054"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9169 13.6198C12.6381 14.147 12.1301 14.4993 11.5503 14.4993C10.6695 14.4993 9.9556 13.6868 9.9556 12.6848V11.6471C9.9556 10.6456 10.6695 9.83314 11.5503 9.83314C12.1301 9.83314 12.6381 10.1854 12.9169 10.7127M9.41724 12.1662H11.7054"
        stroke="url(#paint1_linear_2418_9933)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.593 4.79275C5.36799 3.84275 4.22209 2.98546 2.84598 3.62608C1.46986 4.26775 1.2511 6.33233 3.33246 6.55108C4.27314 6.65004 4.88671 6.4365 5.44716 7.04171C6.00969 7.64692 6.11386 9.33129 4.67837 9.78546C3.24287 10.2396 1.82196 9.53025 1.66675 8.52296M3.73352 2.50004V3.40629M3.73352 9.92504V10.8334"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.593 4.79275C5.36799 3.84275 4.22209 2.98546 2.84598 3.62608C1.46986 4.26775 1.2511 6.33233 3.33246 6.55108C4.27314 6.65004 4.88671 6.4365 5.44716 7.04171C6.00969 7.64692 6.11386 9.33129 4.67837 9.78546C3.24287 10.2396 1.82196 9.53025 1.66675 8.52296M3.73352 2.50004V3.40629M3.73352 9.92504V10.8334"
        stroke="url(#paint2_linear_2418_9933)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3333 4.16671H15.0833M13.3333 4.16671V2.22226H14.3333M13.3333 4.16671V6.11115H14.3333M15.0833 4.16671C15.4973 4.16671 15.8333 3.73115 15.8333 3.19448C15.8333 2.65782 15.4973 2.22226 15.0833 2.22226H14.3333M15.0833 4.16671C15.4973 4.16671 15.8333 4.60226 15.8333 5.13893C15.8333 5.6756 15.4973 6.11115 15.0833 6.11115H14.3333M14.3333 2.22226V1.66671M14.3333 6.11115V6.66671"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3333 4.16671H15.0833M13.3333 4.16671V2.22226H14.3333M13.3333 4.16671V6.11115H14.3333M15.0833 4.16671C15.4973 4.16671 15.8333 3.73115 15.8333 3.19448C15.8333 2.65782 15.4973 2.22226 15.0833 2.22226H14.3333M15.0833 4.16671C15.4973 4.16671 15.8333 4.60226 15.8333 5.13893C15.8333 5.6756 15.4973 6.11115 15.0833 6.11115H14.3333M14.3333 2.22226V1.66671M14.3333 6.11115V6.66671"
        stroke="url(#paint3_linear_2418_9933)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9933"
          x1="-4.20385"
          y1="-37.7038"
          x2="5.52432"
          y2="-38.5978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2418_9933"
          x1="5.40293"
          y1="-12.7688"
          x2="9.0644"
          y2="-13.0212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2418_9933"
          x1="-3.11266"
          y1="-37.8645"
          x2="1.25815"
          y2="-38.0654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2418_9933"
          x1="10.4656"
          y1="-22.552"
          x2="13.0881"
          y2="-22.6725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
