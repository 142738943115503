import {
  AppTextMedium,
  FlexRowBetween,
  TradeLabel,
  FlexColumnStart,
} from '../../style/projectComponents'
import React from 'react'
import styled from 'styled-components/macro'
import toLocaleNumber from '../../utils/toLocaleNumber'
import { useTranslation } from 'react-i18next'

const Block = styled(FlexRowBetween)`
  padding: 15px;
  border-radius: 16px;
  width: 100%;
  max-width: 395px;
  background: ${({ theme }) => theme.colors.gray050};
  margin-bottom: 20px;
  height: 114px;
  align-items: center;

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 920px) {
    max-width: 100%;
  }
`

interface TotalReferralsInfoBlock {
  totalReferrals: number
  totalProfit: number
}

export const TotalReferralsInfoBlock: React.FC<TotalReferralsInfoBlock> = ({
  totalReferrals,
  totalProfit,
}) => {
  const { t } = useTranslation()
  return (
    <Block>
      <FlexColumnStart style={{ minWidth: 120 }}>
        <TradeLabel>{t('common.totalReferrals')}</TradeLabel>
        <AppTextMedium>{totalReferrals}</AppTextMedium>
      </FlexColumnStart>
      <FlexColumnStart style={{ minWidth: 120 }}>
        <TradeLabel>{t('dashboard.total_profit')}</TradeLabel>
        <AppTextMedium>{toLocaleNumber(totalProfit)}</AppTextMedium>
      </FlexColumnStart>
    </Block>
  )
}
