// disable auto complete (autofill)

import Select, { components, GroupBase } from 'react-select'
import { InputSpecificProps } from 'react-select/dist/declarations/src/components/Input'

const Input = (props: InputSpecificProps<unknown, boolean, GroupBase<unknown>>) => (
  <components.Input {...props} autoComplete={'nope'} />
)

export const CustomSelect = ({ ...rest }) => <Select {...rest} components={{ Input }} />
