import { useTheme } from 'styled-components/macro'

export const WithdrawalRequestsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.08325 9.99996C2.08325 6.47496 2.08325 4.71246 3.08159 3.54412C3.22381 3.37746 3.37797 3.22329 3.54409 3.08162C4.71242 2.08329 6.47492 2.08329 9.99992 2.08329C13.5249 2.08329 15.2874 2.08329 16.4558 3.08162C16.6224 3.22385 16.7766 3.37801 16.9183 3.54412C17.9166 4.71246 17.9166 6.47496 17.9166 9.99996C17.9166 13.525 17.9166 15.2875 16.9183 16.4558C16.776 16.6225 16.6219 16.7766 16.4558 16.9183C15.2874 17.9166 13.5249 17.9166 9.99992 17.9166C6.47492 17.9166 4.71242 17.9166 3.54409 16.9183C3.37742 16.7761 3.22325 16.6219 3.08159 16.4558C2.08325 15.2875 2.08325 13.525 2.08325 9.99996Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.08325 9.99996C2.08325 6.47496 2.08325 4.71246 3.08159 3.54412C3.22381 3.37746 3.37797 3.22329 3.54409 3.08162C4.71242 2.08329 6.47492 2.08329 9.99992 2.08329C13.5249 2.08329 15.2874 2.08329 16.4558 3.08162C16.6224 3.22385 16.7766 3.37801 16.9183 3.54412C17.9166 4.71246 17.9166 6.47496 17.9166 9.99996C17.9166 13.525 17.9166 15.2875 16.9183 16.4558C16.776 16.6225 16.6219 16.7766 16.4558 16.9183C15.2874 17.9166 13.5249 17.9166 9.99992 17.9166C6.47492 17.9166 4.71242 17.9166 3.54409 16.9183C3.37742 16.7761 3.22325 16.6219 3.08159 16.4558C2.08325 15.2875 2.08325 13.525 2.08325 9.99996Z"
        stroke="url(#paint0_linear_2418_9921)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.3125 8.43976C9.3125 7.51152 8.389 6.75921 7.25 6.75921C6.111 6.75921 5.1875 7.51152 5.1875 8.43976C5.1875 9.368 5.75 9.87958 7.25 9.87958C8.75 9.87958 9.5 10.3601 9.5 11.5601C9.5 12.7606 8.4925 13.2407 7.25 13.2407C6.0075 13.2407 5 12.4884 5 11.5601M7.25 5.83328V14.1666M15 9.99995H11.25M15 9.99995C15 10.324 14.003 10.9296 13.75 11.1574M15 9.99995C15 9.67588 14.003 9.07032 13.75 8.84254"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.3125 8.43976C9.3125 7.51152 8.389 6.75921 7.25 6.75921C6.111 6.75921 5.1875 7.51152 5.1875 8.43976C5.1875 9.368 5.75 9.87958 7.25 9.87958C8.75 9.87958 9.5 10.3601 9.5 11.5601C9.5 12.7606 8.4925 13.2407 7.25 13.2407C6.0075 13.2407 5 12.4884 5 11.5601M7.25 5.83328V14.1666M15 9.99995H11.25M15 9.99995C15 10.324 14.003 10.9296 13.75 11.1574M15 9.99995C15 9.67588 14.003 9.07032 13.75 8.84254"
        stroke="url(#paint1_linear_2418_9921)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9921"
          x1="-16.0785"
          y1="-74.6094"
          x2="0.426253"
          y2="-76.1262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2418_9921"
          x1="-6.47059"
          y1="-34.5313"
          x2="3.91521"
          y2="-35.6766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
