import {
  FlexColumnStart,
  FlexRow,
  StyledButton,
  FlexRowBetween,
  MarketTextBold,
  MarketText,
  ErrorText,
} from '../../../style/projectComponents'
import styled from 'styled-components/macro'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { IAccrualInvestPoolReq, IInvestPool } from '../../../service/investpool-api/models'
import { useMutation } from 'react-query'
import InvestPoolApi from '../../../service/investpool-api/InvestPoolApi'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import { ValidationInputComponent } from '../ValidationInputComponent'
import { getInvestPoolContent } from '../../../utils/getInvestPoolContent'
import {
  getGetApiInvestPoolGetAllInvestPoolsQueryKey,
  InvestPoolDto,
} from '../../../service/api/generated'
import { queryClient } from '../../../config/query-client'

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;

  @media (max-width: 480px) {
    width: 320px;
    label {
      left: 20px;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: ${({ theme }) => theme.colors.modalBg};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 13000;
`

const Content = styled(FlexColumnStart)`
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: var(--8, 0.5rem);
  padding: 20px 25px;
  position: relative;
  width: 400px;
  align-items: center;

  @media (max-width: 480px) {
    padding: 20px 25px;
    width: calc(100% - 70px);
    align-items: center;
    border-radius: 0.75rem;
  }
`

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 0.5rem;
  margin-top: 30px;
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
  }
`

const CloseButton = styled(StyledButton)`
  align-self: end;
  padding: 0;
  filter: ${({ theme }) => theme.colors.black === '#fff' && 'invert(1)'};
`
const InvestLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 8px;
`

interface AdminInvestPoolModalProps {
  setIsOpen: (bool) => void
  investPool: InvestPoolDto
}

const AdminInvestPoolModal: FC<AdminInvestPoolModalProps> = ({ setIsOpen, investPool }) => {
  const { t } = useTranslation()

  const {
    register,
    control,
    clearErrors,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
  } = useForm()

  const investPoolTitles = [
    t('invest.totalInvested'),
    t('invest.yourLimit'),
    t('invest.yourInvestments'),
    t('invest.table.tokens'),
    t('invest.table.period'),
    t('invest.table.term'),
    t('invest.holdPeriod'),
  ]
  const investPoolContent = getInvestPoolContent(investPool, investPoolTitles)

  const logo = investPool.logoDownloadLink

  const GlobalModalStorage = useContext(GlobalModalStore)
  const accrualMutation = useMutation((data: IAccrualInvestPoolReq) =>
    InvestPoolApi.accrualOfInterestInInvestPool(data)
  )
  const closePoolMutation = useMutation((data: { poolId: string }) =>
    InvestPoolApi.closeInvestPool(data)
  )

  const onSubmit = data => {
    const accrualReq = {
      id: investPool.id,
      percents: parseFloat(data.percent),
    }
    accrualMutation.mutate(accrualReq, {
      onSuccess: data => {
        queryClient.invalidateQueries({
          queryKey: getGetApiInvestPoolGetAllInvestPoolsQueryKey(),
        })
        GlobalModalStorage.makeVisible(true, t('common.success'))
        reset({
          amount: '',
        })
        setIsOpen(false)
      },
      onError: error => {
        GlobalModalStorage.makeVisible(false, t('common.error'))
      },
    })
  }
  const closePool = () => {
    closePoolMutation.mutate(
      { poolId: investPool.id },
      {
        onSuccess: data => {
          queryClient.invalidateQueries({
            queryKey: getGetApiInvestPoolGetAllInvestPoolsQueryKey(),
          })

          GlobalModalStorage.makeVisible(true, t('common.success'))
          setIsOpen(false)
        },
        onError: error => {
          GlobalModalStorage.makeVisible(false, t('common.error'))
        },
      }
    )
  }

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setIsOpen(false)
        }
      }}
    >
      <Content>
        <FlexRowBetween
          style={{
            alignItems: 'center',
            borderBottom: `1px solid ${({ theme }) => theme.colors.lightGray};`,
            paddingBottom: 12,
          }}
        >
          <FlexRow>
            <InvestLogo src={logo} alt="" />
            <MarketTextBold>{investPool.name}</MarketTextBold>
          </FlexRow>
          <CloseButton onClick={() => setIsOpen(false)} variant={'text'}>
            <img src={'/images/ic_cancel.svg'} />
          </CloseButton>
        </FlexRowBetween>

        <InnerWrapper>
          {investPoolContent.map((i, key) => (
            <FlexRowBetween key={i.title}>
              <MarketText>{i.title}</MarketText>
              <MarketTextBold>{i.value}</MarketTextBold>
            </FlexRowBetween>
          ))}

          <FlexColumnStart style={{ width: '100%', marginTop: 16 }}>
            <InputWrapper style={{ marginBottom: 5, marginTop: 16 }}>
              <Controller
                name="percent"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Required',
                  },
                  pattern: {
                    value: /^-?\d{1,7}(?:\.\d{1,2})?$/,
                    message: 'adminPercent.errors.max',
                  },
                  max: {
                    value: 1000,
                    message: 'adminPercent.errors.max',
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <ValidationInputComponent
                    {...field}
                    type="text"
                    isError={!!errors['percent']}
                    label={t('adminPercent.percent')}
                  />
                )}
              />
            </InputWrapper>

            <ErrorText hasError={!!errors['amount']}>
              {t(errors['amount']?.message?.toString())}
            </ErrorText>
          </FlexColumnStart>

          <StyledButton
            onClick={() => clearErrors()}
            variant="black"
            type="submit"
            style={{ width: '100%', height: 50, marginTop: '1.5rem' }}
          >
            {t('adminPercent.accrualInterest')}
          </StyledButton>
          {!investPool.isClosed && (
            <StyledButton
              onClick={closePool}
              variant="black"
              type="button"
              disabled={closePoolMutation.isLoading}
              style={{
                width: '100%',
                height: 50,
                background: `${({ theme }) => theme.colors.redFail};`,
              }}
            >
              Close pool
            </StyledButton>
          )}
        </InnerWrapper>
      </Content>
    </StyledForm>
  )
}

export default AdminInvestPoolModal
