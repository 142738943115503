import { En } from './translation_en'

export const ru: En = {
  lg: 'ru',
  common: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    balance: 'Баланс',
    startingBalance: 'Начальный баланс',
    operationDate: 'Дата операции',
    createdOn: 'Дата создания',
    updatedOn: 'Дата исполнения',
    operationActorEmail: 'Email',
    walletOwnerEmail: 'Email',
    userName: 'Email',
    balanceDiff: 'Баланс',
    balanceDiffInUsd: 'USD Баланс',
    balanceDiffPercent: 'Процент',
    type: 'Тип операции',
    status: 'Статус',
    approved: 'Принято',
    undefined: 'Неизвестно',
    notApproved: 'В ожидании',
    rejected: 'Отклонено',
    cancelled: 'Отменено',
    withdrawalRequest: 'Заявка на вывод',
    withdrawalAmount: 'Сумма',
    deposit: 'Депозит',
    accrualOfInterest: 'Начисление процентов',
    forgotPassword: 'Забыли пароль?',
    success: 'Успешно',
    error: 'Произошла ошибка',
    imageSizeOrTypeError: 'Некорректный размер или тип картинки',
    withdrawErrorNotification: 'Вывод {{amount}} отменен',
    withdrawSuccessNotification: 'Вывод {{amount}}',
    accepted: 'Подтверждено',
    errorModal: 'Ошибка',
    next: 'Далее',
    prev: 'Назад',
    id: 'Id',
    name: 'Имя',
    coinGeckoId: 'Coingecko Id',
    usdPrice: 'Цена USD',
    currency: 'Валюта',
    actions: 'Действия',
    referrals: 'Рефералы',
    copySuccess: 'Успешно скопировано',
    copyFailed: 'Ошибка копирования',
    email: 'Почта',
    level: 'Уровень',
    income: 'Доход',
    address: 'Адрес',
    evm: 'EVM',
    ownerId: 'ID владельца',
    myReferrals: 'Реферальная программа',
    myPartners: 'Мои партнеры',
    totalReferrals: 'Количество рефералов',
    profit: 'Прибыль',
    investPoolTransaction: 'Транзакция инвест пула',
    accrualOfInterestFromInvestPool: 'Начисление доходности с инвест пула',
    exchange: 'Обмен',
    accrualOfInterestFromRefral: 'Начисление доходности от реферала',
    commission: 'Комиссия',
    language: 'Язык',
    loading: 'Загрузка',
    sec: 'сек',
    howItWorks: 'Как это работает',
    titleFull: 'Time.Investments ',
    defTitleFull: 'Defence.Investments ',
  },
  referral: {
    title1: 'О ',
    title2: 'реферальной программе ',
    subTitle:
      'Приглашайте партнеров и получайте вознаграждение за каждого, кто выполнит условия программы.',
    refLink: 'Реферальная ссылка',
    refLinkDesc: 'Скопируйте ссылку чтобы пригласить партнера в вашу команду',
    registration: 'Регистрация',
    retDesc: 'Зарегистрируйте личный кабинет партнера.',
    profit: 'Получайте прибыль',
    profitDesc:
      'Получайте 5% от каждой инвестиции вашего партнера, в течение всего срока действия программы! Ваша команда может принести вам до 8% прибыли!',
  },
  forgotPassword: {
    title: 'Сброс пароля',
    letterSent: 'Письмо успешно отправлено',
    update: 'Обновить',
    newPass: 'Новый пароль',
  },
  settings: {
    title: 'Настройки',
    save: 'Сохранить',
    contacts: 'Контакты',
    inputs: {
      phone: {
        title: 'Номер телефона',
        errors: {
          required: 'Номер телефона необходим',
        },
      },
      password: {
        title: 'Пароль',
        errors: {
          required: 'Пароль необходим',
          minLength: 'Минимальная длина 8 символов',
          pattern: 'Пароль должен содержать минимум 1 заглавную букву и 1 цифру',
        },
      },
    },
  },
  adminAnalytics: {
    totalUsers: 'Количество пользователей',
    totalDeposits: 'Сумма депозитов',
    currentInvestPoolsIncome: 'Текущий доход всех инвестпулов',
    tvl: 'TVL',
    depositsAmount: 'Сумма депозитов',
    depositsFlow: 'Приток депозитов',
    balanceSum: 'Сумма балансов',
    registration: 'Регистрации',
    quantity: 'Кол-во',
    users: 'Пользователи',
  },
  adminUserDetail: {
    commonInfo: 'Общая информация',
    save: 'Сохранить',
    name: {
      placeholder: 'Введите имя',
      label: 'ФИО',
    },
    balances: {
      title: 'Размеры депозитов',
      balancesZero: 'Пустой баланс',
      balancesFrom0To1000: 'от 0 до 1000',
      balancesFrom1000To5000: 'от 1000 до 5000',
      balancesFrom5000To10000: 'от 5000 до 10 000',
      balancesFrom10000To20000: 'от 10 000 до 20 000',
      balancesFrom20000To50000: 'от 20 000 до 50 000',
      balancesOverThan50000: 'от 50 000',
    },
    email: {
      placeholder: 'Введите адрес электронной почты',
      label: 'Email',
    },
    phoneNumber: {
      placeholder: 'Введите номер телефона',
      label: 'Номер телефона',
    },
    birthDate: {
      placeholder: 'Введите дату рождения',
      label: 'Дата рождения',
    },
    sum: {
      label: 'Сумма',
    },
    addBalance: 'Начислить баланс',
    addPercent: 'Начислить процент',
    addCommission: 'Коммиссия пользователя',
    commissionRule: 'от 0 до 100',
    inputRule: 'от 0 до 1 млрд с 18 знаками после запятой',
    graphBalance: 'График баланса',
    initialBalance: 'Установить начальный баланс',
    setWithdrawalBalance: 'Баланс вывода',
  },
  adminWithdraw: {
    withdrawRequests: 'Заявки на вывод',
    transactionEmpty: 'Транзакции отсутствуют',
    comment: 'Комментарий',
    accept: 'Принять',
    decline: 'Отклонить',
  },
  adminWithdrawHistory: {
    withdrawHistory: 'История выводов',
  },
  adminPercent: {
    accrualInterest: 'Начисление процента',
    percent: 'Процент',
    processus: 'В процессе',
    add: 'Начислить',
    errors: {
      max: 'Максимум 1000%',
    },
  },
  adminOperations: {
    operationHistory: 'История операций',
  },
  menu: {
    about: 'О нас',
    dashboard: 'Аналитика',
    marketplace: 'Маркетплейс',
    deposit: 'Активы',
    withdraw: 'Вывод',
    settings: 'Настройки',
    phone: 'Телефон',
    email: 'Почта',
    logout: 'Выйти',
    exit: 'Выход',
    cancel: 'Отменить',
    exitConfirm: 'Вы точно хотите выйти?',
    withdrawRequests: 'Заявки на вывод',
    withdrawHistory: 'История выводов',
    historyOperations: 'История операций',
    profit: 'Доходность',
    principles: 'Ценности',
    plans: 'Планы',
    cooperation: 'Сотрудничество',
    adminPanel: 'Админ',
    backToUser: 'Назад на пользователя',
    markets: 'Рынки',
    convert: 'Конвертация',
    referrals: 'Рефералы',
    usersList: 'Список пользователей',
  },
  withdraw: {
    title: 'Выберите способ вывода',
    eth_address: 'ETH адрес',
    trc_address: 'TRC адрес',
    btc_address: 'BTC address',
    amount: 'Сумма',
    withdraw: 'Вывести',
    withdrawProfit: 'Прибыль к выводу',
    available: 'Доступно',
    copy: 'Скопировано',
    errors: {
      wallet: {
        required: 'Поле не должно быть пустым',
        pattern: 'Неверный адрес',
      },
      amount: {
        required: 'Поле не должно быть пустым',
        pattern: 'Введите число',
        max: 'Нельзя превышать текущий баланс',
        min: 'Минимум 0.000001',
      },
    },
  },
  assets: {
    totalAssets: 'Активы',
    asset: 'Актив',
    balance: 'Баланс',
    actions: 'Действия',
    deposit: 'Депозит',
    withdraw: 'Вывод',
  },
  deposit: {
    title: 'Выберите способ пополнения',
    pay_method: 'Ваш адрес пополнения',
    select_network: 'Выберете сеть',
    description: {
      '1': '1. Важно. Переводите только {{title}} на этот адрес депозита.',
      '2': '2. Минимальный депозит 100 000 USDT',
      '3': '3. После внесения депозита свяжитесь с вашим менеджером',
    },
    contacts: 'Контакты',
    phone: 'Номер телефона',
  },
  market: {
    title: 'Рынок',
    description:
      'Просматривайте актуальные цены вместе с их суточными колебаниями и другой статистикой рыночной капитализации.',
    crypto: 'Криптоавалюта',
    searchCrypto: 'Поиск криптовалюты',
    price: 'Цена',
    lastPrice: 'Последняя цена',
    h24: 'Изм (24ч)',
    low24: 'Минимум (24ч)',
    high24: 'Максимум (24ч)',
    volume: 'Объем (24ч)',
    marketCap: 'Капитализация',
    orderBook: 'Стакан цен',
    turnover24h: 'Оборот (24ч)',
    name: 'Наименование',
    favorites: 'Избранное',
    spot: 'Спот',
  },
  invest: {
    title: 'Инвестиционные продукты',
    description: 'Выбирайте и инвестируйте в любые активные направления на нашей площадке.',
    invest: 'Инвестировать',
    yourInvestments: 'Ваши инвестиции',
    totalInvested: 'Инвестировано в пул',
    yourLimit: 'Ваш лимит',
    holdPeriod: 'Период холда',
    details: 'Подробнее',
    about: 'Об активе',
    table: {
      title: 'Продукт',
      description: 'Описание',
      tokens: 'Доступные токены',
      estApr: 'Ожид. APR',
      term: 'Срок',
      period: 'Периодичность',
    },
  },
  dashboard: {
    balance: 'Общий баланс',
    total_profit: 'Общая прибыль',
    total_invest_profit: 'Общая инвест прибыль',
    current_invest_income: 'Текущая инвест прибыль',
    not_withdraw_profit: 'Прибыль к выводу',
    current_invest_pool_balance: 'Инвестировано в пулы',
    available_invest_pool_balance: 'К выводу в пулах',
    withdrawProfit: 'Прибыль к выводу',
    fund_balance: 'Общий капитал',
    prev_dividends: 'Предыдущие дивиденды',
    chart_title: 'График баланса в USD',
    operations: 'История операций',
    date: 'Дата',
    amount: 'Сумма',
    operation: 'Операция',
    funding: 'Фандинг',
    deposit: 'Депозит',
    withdraw: 'Вывод',
    empty_data: 'Вы еще не совершили ни одной операции',
    divHistory: 'История дивидендов',
    chart: {
      week1: '1Н',
      month1: '1М',
      month3: '3М',
      year1: '1Г',
      all: 'Все',
    },
    filter: {
      operationType: 'Тип операции',
      all: 'Все',
      deposit: 'Депозит',
      withdrawal: 'Вывод',
      dividends: 'Дивиденды',
    },
  },
  auth: {
    title: 'Войти',
    reg_title: 'Создать аккаунт',
    email: 'Почта',
    password: 'Пароль',
    retype_password: 'Повторите пароль',
    login: 'Войти',
    not_registered: 'Еще не зарегистрированы?',
    already_has: 'Уже есть аккаунт?',
    create_acc: 'Создать аккаунт',
    name: 'Имя',
    surname: 'Фамилия',
    middlename: 'Отчество',
    phone: 'Телефон',
    sendCode: 'Отправить код',
    codeSent: 'Код был отправлен',
    successCode: 'Код успешно отправлен',
    newCodeCanBeSentIn: 'Новый код можно будет отправить через',
    code: 'Код подтверждения почты',
    continue: 'Продолжить',
    birthday: 'Дата рождения',
    noUsCitizen:
      'Я подтверждаю, что я не являюсь гражданином США (в соответствии с Законом США о ценных бумагах 1933 г).',
    iConfirm: 'Продолжая я согласен с',
    and: 'и',
    twoFa: 'Двухфакторная Аутенфикация',
    enableTwoFa: 'Включить 2ФA',
    disableTwoFa: 'Отключить 2ФA',
    enterYour2fa: 'Введите ваш 2ФА код',
    referralCode: 'Реферальный код',
    referralLink: 'Реферальная ссылка',
    presentation: {
      welcome: 'Добро пожаловать в',
      title: 'Time Invest',
      defTitle: 'Defence Invest',
      description:
        'Присоединяйтесь к Time Investments прямо сейчас! Инвестируйте, подписывайтесь и зарабатывайте больше.',
      defDescription:
        'Присоединяйтесь к Defence Investments прямо сейчас! Инвестируйте, подписывайтесь и зарабатывайте больше.',
      description2:
        'Доступ к эксклюзивным инвестиционным возможностям в Pre-IPO, венчурном капитале, фондах, недвижимости и криптовалютах для инвесторов всех уровней. Ваши инвестиции всегда под рукой!',
      reward: 'Получите вознаграждение за приглашенных друзей',
      rewardAmount: '$30,000',
      rewardText:
        'Инвестиционный бонус от первых партнеров, а также 3% за приглашенных ими друзей и 2% от остальной части вашей ветки. Получайте вознаграждения и повышайте свой статус до VIP!',
      historyText2015: 'Опыт работы на крипторынке',
      historyYear: 'г',
      historyTransaction: 'Успешных транзакций',
      historyYeld: 'Средняя доходность',
      historyArea: 'Областей инвестирования',
      depositRow1Text1: 'Разместите',
      depositRow1Text2: 'и сделейте',
      depositRow1Text3: 'свои первые инвестиции',
      depositRow2Text1: 'Получайте до 5% инвестиций от ',
      depositRow2Text2: 'приглашенных партнеров',
      depositRow3Text1:
        'Реферальная программа позволяет вам зарабатывать более 3050$ в месяц от ваших партнеров',
      depositRow4Text1: 'Если общий капитал вашей команды',
      depositRow4Text2: 'превысит $300.000,',
      depositRow4Text3:
        'вы ежемесячно получаете повышенное вознаграждение в размере 10% от реферальной программы, получаете',
      depositRow4Text4: 'VIP cтатус',
      depositRow5Text1: 'Улучшенная реферальная система',
      depositRow5Text2:
        'Все VIP-участники могут получать такой доход за счет того, что платформа снимает с них свои комиссии. ',
      depositRow5Text3: 'не зарабатывает комиссионные с VIP-клиентов. ',
      neverMissTitle: 'Никогда не упускайте ни единого момента с ',
      neverMissCard1: 'Онлайн поддержка 24/7',
      neverMissCard2: 'Покупайте активы в 2 клика',
      neverMissCard2_2: 'Эксклюзивные предложения от $100 до $500 000',
      neverMissCard3: 'Доступно с любого устройства (веб, iOS, Android)',
      neverMissCard4: 'Персональный менеджер для клиентов от $100000',
    },
    errors: {
      email: {
        required: 'Поле не может быть пустым',
        pattern: 'Введите корректный адрес',
      },
      password: {
        required: 'Поле не может быть пустым',
        minLength: 'Минимальная длина 8 символов',
        pattern: 'Пароль должен содержать минимум 1 заглавную букву и 1 цифру',
        validate: 'Пароли не совпадают',
        serverError: 'Ошибка при входе попробуйте снова',
        serverRegError: 'Ошибка при регистрации попробуйте снова',
        serverRegErrorUserExist: 'Пользователь с данным емейлом или телефоном уже существует',
      },
      birthday: {
        required: 'Поле не может быть пустым',
        pattern: 'Введите в формате ДД-MM-ГГГГ',
      },
      name: {
        required: 'Поле не может быть пустым',
      },
      surname: {
        required: 'Поле не может быть пустым',
      },
      middlename: {
        required: 'Поле не может быть пустым',
      },
      phone: {
        required: 'Поле не может быть пустым',
      },
    },
  },
  footer: {
    profit: 'Доходность',
    leaders: 'Лидеры рынка',
    principles: 'Ценности',
    strategy: 'Стратегии',
    team: 'Команда',
    plans: 'Планы',
    serts: 'Сертификат',
    cooperation: 'Сотрудничество',
    aml: 'Политика AML',
    privacy: 'Уведомление о конфиденциальности',
    termsOfUse: 'Условия использования',
    subscription: 'Форма договора',
    juridic: 'Юридический адрес',
    addressJur:
      'Intershore Chambers, почтовый ящик 4342, Роуд-Таун, Тортола, Британские Виргинские острова.',
    mainOffice: 'Главный офис',
    mainAddress: 'Азербайджан, Баку, Нефтчи Гурбан 29, AZ1003, "БАИЛ Плаза", офис 8.',
    allRights: 'Все права защищены.',
    menu: 'Меню',
    community: 'Сообщество',
  },
  landing: {
    join: 'Попробовать систему',
    title: 'FinTech и Blockchain будущее у вас в руках',
    description1: 'Наше приложение служит основой для создания разнообразных прорывных приложений.',
    description2:
      'Будь то криптовалютная биржа, централизованный или децентрализованный криптовалютный кошелек, платежное решение или передовая аналитическая платформа для управления активами, наш универсальный продукт - ваш ключ к открытию бесконечных возможностей.',
    whyChooseUs: {
      title: 'Почему наша система?',
      versatility: 'Универсальность',
      versatilityDesc:
        'Наш продукт предоставляет гибкость для создания решений различных потребностей в области финансовых технологий, гарантируя идеальное соответствие ваших уникальных требований.',
      security: 'Безопасность',
      securityDesc:
        'С прочной архитектурой безопасности, будьте уверены, что ваши финансовые транзакции и данные надежно защищены от потенциальных угроз.',
      scalability: 'Масштабируемость',
      scalabilityDesc:
        'Независимо от того, вы стартап или уже рабочий бизнес, наша система разработки масштабируется плавно, чтобы соответствовать вашему росту и изменяющимся требованиям.',
      innovation: 'Инновации',
      innovationDesc:
        'Оставайтесь впереди конкурентов, используя наши передовые функции и технологии, создавая основу для вашего успеха в динамичном мире Blockchain&Fintech.',
    },
    explore: {
      title: 'Возможности системы',
      one1: 'Криптобиржа',
      one2: 'Запустите свою собственную безопасную и эффективную платформу для обмена криптовалют.',
      two1: 'Криптокошелек',
      two2: 'Предоставьте пользователям безопасное и удобное решение для хранения, поддерживающее как традиционные, так и криптовалютные активы.',
      three1: 'Инвест Фонд',
      three2:
        'Создайте и управляйте своим инвестиционным фондом, начисляйте проценты, проводите KYC и управляйте депозитами своих пользователей.',
      four1: 'Аналитика',
      four2:
        'Предоставьте своим пользователям доступ к финансовым исследованиям и рыночной динамике, которые позволят принимать обоснованные решения.',
    },
  },
}
