import { ChartComponent } from '../../DashboardComponents/ChartComponent'
import { FC } from 'react'
import styled from 'styled-components/macro'
import { FlexColumn } from '../../../style/projectComponents'
import { DataEmptyComponent } from '../../global/DataEmptyComponent'
import { useTranslation } from 'react-i18next'
import { AdminDepositsComponent } from './AdminDepositsComponent'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  BalancesStatisticDto,
  DepositsStatisticDto,
  GetSystemBalancesStatisticResponse,
  UsersStatisticDto,
} from '../../../service/api/generated'

const Wrapper = styled.div`
  margin-bottom: 48px;
`
const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1000px;
  justify-content: start;
  background: ${({ theme }) => theme.colors.gray050};
  padding: 26px 16px;
  margin: 24px;
  border-radius: 8px;
  @media (max-width: 1300px) {
    max-width: calc(100% - 16px);
  }
`

const DepositsWrapper = styled(BalanceChartWrapper)``

const ChartTitle = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
`

export const AdminAnalyticsBase: FC<{
  isUsersStatsLoading?: boolean
  isDepositsStatsLoading?: boolean
  isBalancesStatsLoading?: boolean
  isSystemBalancesLoading?: boolean
  usersStatsData?: UsersStatisticDto
  depositsStatsData?: DepositsStatisticDto[]
  systemBalancesStatisticsData: GetSystemBalancesStatisticResponse
  balanceStatsData: BalancesStatisticDto[]
}> = ({
  isUsersStatsLoading,
  isDepositsStatsLoading,
  isBalancesStatsLoading,
  isSystemBalancesLoading,
  usersStatsData,
  depositsStatsData,
  systemBalancesStatisticsData,
  balanceStatsData,
}) => {
  const { t } = useTranslation()
  const getDate = (date: string) => format(new Date(date), 'dd.MM.yy', { locale: ru })

  return (
    <Wrapper>
      <BalanceChartWrapper>
        <ChartTitle>{t('adminAnalytics.depositsFlow')}</ChartTitle>
        {!isDepositsStatsLoading && depositsStatsData?.length > 0 ? (
          <ChartComponent
            titleKey={t('adminAnalytics.depositsAmount')}
            valueKey={'depositsAmountInUsd'}
            xAxisKey={'date'}
            yAxisKey={'depositsAmountInUsd'}
            data={depositsStatsData
              ?.find(s => s.currency === null)
              ?.depositsPerYears?.at(0)
              ?.depositsPerDays?.map(it => ({
                ...it,
                date: getDate(it.date),
              }))}
          />
        ) : (
          <DataEmptyComponent isChart />
        )}
      </BalanceChartWrapper>
      <BalanceChartWrapper>
        <ChartTitle>{t('adminAnalytics.balanceSum')}</ChartTitle>
        {!isSystemBalancesLoading &&
        systemBalancesStatisticsData?.systemBalanceStatisticItems?.length > 0 ? (
          <ChartComponent
            titleKey={t('adminAnalytics.balanceSum')}
            valueKey={'totalBalanceInUsd'}
            yAxisKey={'totalBalanceInUsd'}
            xAxisKey={'date'}
            data={systemBalancesStatisticsData?.systemBalanceStatisticItems
              ?.find(s => s.currency === null)
              ?.systemBalancesPerYears?.at(0)
              ?.systemBalancesPerDays?.map(it => ({
                ...it,
                date: getDate(it.date),
              }))}
          />
        ) : (
          <DataEmptyComponent isChart />
        )}
      </BalanceChartWrapper>
      <BalanceChartWrapper>
        <ChartTitle>{t('adminAnalytics.registration')}</ChartTitle>
        {!isUsersStatsLoading && usersStatsData?.registrationsPerDays.length > 0 ? (
          <ChartComponent
            titleKey={t('adminAnalytics.quantity')}
            valueKey={'registrationsCount'}
            variant={'linear'}
            yAxisKey={'registrationsCount'}
            xAxisKey={'date'}
            data={usersStatsData?.registrationsPerDays?.map(it => ({
              ...it,
              date: getDate(it.date),
            }))}
          />
        ) : (
          <DataEmptyComponent isChart />
        )}
      </BalanceChartWrapper>

      <DepositsWrapper>
        <ChartTitle>{t('adminUserDetail.balances.title')}</ChartTitle>
        {!isBalancesStatsLoading && balanceStatsData?.length > 0 ? (
          <AdminDepositsComponent data={balanceStatsData} />
        ) : (
          <DataEmptyComponent />
        )}
      </DepositsWrapper>
    </Wrapper>
  )
}
