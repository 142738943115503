import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  StyledButton,
  StyledLink,
} from '../../../style/projectComponents'
// import { StyledButton as StyledButtonTime } from '../../../style/projectTimeComponents'
import { Link as ScrollLink } from 'react-scroll'
import styled, { useTheme } from 'styled-components/macro'
import React, { ComponentPropsWithoutRef, FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { adminLinks, headerAuthLinks } from '../headerLinks'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AdminPercent } from '../../../pages/AdminPages/AdminPercent'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { observer } from 'mobx-react-lite'
import { LogoutModal } from './LogoutModal'
import { AdminCreateInvestPool } from '../../../pages/AdminPages/AdminCreateInvestPool'
import { PROJECT_NAME } from '../../../config/serverConstants'
import { ExitIcon } from '../../../assets/svgIcons/ExitIcon'
import LanguageModal from './LanguageModal'
// import * as Def from '../HeaderComponent/styles/HeaderStylesDef'
// import * as Time from '../HeaderComponent/styles/HeaderStylesTime'
import { HeaderImgDef } from '../HeaderComponent/images/HeaderImgDef'
import { HeaderImgTime } from '../HeaderComponent/images/HeaderImgTime'
import { useGlobalMenuSizeState } from '../../../store/MenuSizeContext'
import { AdminSvgIcon } from '../../../assets/svgIcons/AdminSvgIcon'
import { ThemeButton, ThemeButtonsWrapper } from '../HeaderComponent/styles/HeaderStylesDef'

// const StyledButton = PROJECT_NAME === 'Def' ? StyledButtonDef : StyledButtonTime
const Box = styled(FlexColumnStart)<{ $isAuth?: boolean; isOpen?: boolean }>`
  display: ${({ $isAuth }) => ($isAuth ? 'block' : 'none')};

  z-index: 10000;
  position: ${({ $isAuth }) => ($isAuth ? 'fixed' : '0')};
  width: ${({ $isAuth }) => ($isAuth ? '220px ' : '0')};
  height: ${({ $isAuth }) => ($isAuth ? '100vh' : '0')};
  bottom: ${({ $isAuth }) => ($isAuth ? '0' : '')};
  right: ${({ $isAuth }) => ($isAuth ? '0' : '')};
  left: ${({ $isAuth }) => ($isAuth ? '0' : '')};
  top: ${({ $isAuth }) => ($isAuth ? '0' : '')};

  @media (max-width: 800px) {
    z-index: -1;
    position: ${({ $isAuth, isOpen }) => ($isAuth && isOpen ? 'fixed' : '0')};
    width: ${props => (props.$isAuth && props.isOpen ? '100vw' : '0')};
    height: ${props => (props.$isAuth && props.isOpen ? '100vh' : '0')};
    bottom: ${props => (props.$isAuth ? '0' : '')};
    right: ${props => (props.$isAuth ? '0' : '')};
    left: ${props => (props.$isAuth ? '0' : '')};
    top: ${props => (props.$isAuth ? '0' : '')};
    background: ${({ theme }) => theme.colors.modalBg};
  }
`

const Wrapper = styled(FlexColumnStart)<{
  $isAuth?: boolean
  open?: boolean
  isScrolled?: boolean
  $isAdmin?: boolean
}>`
  height: 100vh;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.gray050};
  z-index: 100;
  padding-top: 20px;
  padding-left: 50px;
  left: 0;
  justify-content: flex-start;
  transform: translateX(${({ $isAuth }) => ($isAuth ? '0' : '-150%')});
  transition: 0.25s;
  @media (max-width: 800px) {
    left: 0;
    top: 0;
    height: auto;
    border-radius: 0;
    z-index: -1;
    width: auto;
    min-width: 100%;
    border-left: none;
    align-items: center;
    transform: translateX(${({ open }) => (open ? '0' : '-150%')});
    padding-top: 82px;
    padding-bottom: 50px;
    padding-left: 0;
  }
`

const CustomStyledLink = styled(StyledLink)<{
  mini?: boolean
  $isAuth?: boolean
  $isSelected?: boolean
}>`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  transition: 0.5s;
  background: ${({ $isSelected }) =>
    $isSelected
      ? PROJECT_NAME === 'Def'
        ? 'linear-gradient(84.75deg, #FF8A00 -55.87%, #D449F7 40.4%)'
        : '#58d7d6'
      : 'none'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ $isSelected, theme }) =>
    $isSelected ? 'transparent' : theme.colors.black};

  gap: 5px;
  display: flex;
  flex-direction: row;
  padding: 8px 0;

  &[data-admin-btn] {
    flex-direction: row;
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }
`

export type IMenuModalProps = {
  isAuth?: boolean
  open: boolean
  handleMenu: () => void
  isScrolled?: boolean
  toggleTheme: any
  isDarkTheme: boolean
}

const ExitBtn = styled(FlexRow)`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  gap: 5px;

  @media (max-width: 800px) {
    font-size: 16px;
  }

  span {
    color: ${({ theme }) => theme.colors.redFail};
  }
`
const LanguageBtn = styled(ExitBtn)`
  img {
    filter: ${({ theme }) => theme.colors.black === '#000' && 'invert(1)'};
  }

  span {
    color: ${({ theme }) => theme.colors.black};
  }
`

const BtnWrapper = styled(FlexColumn)`
  gap: 1rem;
  margin-top: 3.75rem;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const StyledScrollLink = styled(ScrollLink)<{
  $isAuth?: boolean
  mini?: boolean
}>`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
  font-weight: ${props => (props.$isAuth && '400') || (!props.mini && '300')};
  line-height: 110%;
  cursor: pointer;
  padding: 8px 0;

  @media (max-width: 800px) {
    font-size: 16px;
  }
`

const ColumnMenu = styled(FlexColumnStart)`
  gap: 20px;
  width: 100%;
  @media (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
`
const LogoWrapper = styled(FlexRow)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  user-select: none;
  gap: 10px;
  @media (max-width: 800px) {
    display: none;
  }
`
const Logo = styled.img`
  width: 85px;
  filter: ${({ theme }) => theme.colors.black === '#000' && 'invert(1)'};
`

// const ThemeButton = PROJECT_NAME === 'Def' ? Def.ThemeButton : Time.ThemeButton
// const ThemeButtonsWrapper =
//   PROJECT_NAME === 'Def' ? Def.ThemeButtonsWrapper : Time.ThemeButtonsWrapper
const Images = {
  Def: HeaderImgDef,
  Time: HeaderImgTime,
}

const MenuModal: FC<IMenuModalProps & ComponentPropsWithoutRef<'div'>> = props => {
  const { open, handleMenu, isAuth, toggleTheme, isDarkTheme, ...other } = props
  const { t } = useTranslation()
  const location = useLocation()
  const isAdminPaths = location.pathname.includes('admin')
  const [visiblePercent, setVisiblePercent] = useState<boolean>(false)
  const [createInvestPool, setCreateInvestPool] = useState<boolean>(false)
  const [visibleLogout, setVisibleLogout] = useState<boolean>(false)
  const UserStore = useContext(UserStorage)
  const isAdmin = UserStore.isAdmin()
  const navigate = useNavigate()

  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflowY = 'hidden'
  //   } else {
  //     document.body.style.overflowY = 'auto'
  //   }
  // }, [open])

  const [openLangModal, setOpenLangModal] = useState(false)
  const handleLangMenu = () => {
    setOpenLangModal(bool => !bool)
  }
  const theme = useTheme()
  const { menuSize, setMenuSize } = useGlobalMenuSizeState()
  const isMenuSmall = menuSize === 100
  const handleMenuSize = () => {
    isMenuSmall ? setMenuSize(220) : setMenuSize(100)
  }

  return (
    <>
      <Box isOpen={open} $isAuth={isAuth}>
        <Wrapper
          $isAuth={isAuth}
          $isAdmin={isAdmin}
          open={open}
          {...other}
          style={{ width: menuSize, paddingLeft: isMenuSmall ? 30 : 50 }}
        >
          <ColumnMenu>
            <LogoWrapper
              style={
                isMenuSmall
                  ? {
                      flexDirection: 'column-reverse',
                      alignItems: 'flex-start',
                      gap: '35px',
                      marginBottom: '8px',
                    }
                  : {}
              }
            >
              <Link
                to={'/'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Logo
                  src={
                    PROJECT_NAME === 'Time' && isMenuSmall
                      ? '/images/Time/logo-small.svg'
                      : Images[PROJECT_NAME].logo
                  }
                  alt="logo"
                  style={{ width: !isMenuSmall ? 85 : PROJECT_NAME === 'Time' ? 25 : 45 }}
                />
              </Link>
              <FlexColumn
                onClick={handleMenuSize}
                style={{
                  cursor: 'pointer',
                  marginRight: 10,
                  transform: isMenuSmall && 'rotate(180deg)',
                }}
              >
                <img
                  src="/images/interface/menu-left-arrow.svg"
                  alt=""
                  style={{ filter: theme.colors.black === '#000' && 'invert(1)' }}
                  width={16}
                />
              </FlexColumn>
            </LogoWrapper>
            {(isAuth && isAdminPaths ? adminLinks : headerAuthLinks).map((it, idx) =>
              isAuth ? (
                <React.Fragment key={idx}>
                  {it?.to && (
                    <CustomStyledLink
                      to={it?.to || '/'}
                      key={idx}
                      $isAuth={isAuth}
                      onClick={() => handleMenu()}
                      $isSelected={location.pathname === it.to}
                    >
                      {it?.icon && <it.icon isActive={location.pathname === it.to} />}
                      {!isMenuSmall && (
                        <p style={{ fontSize: isAdminPaths && 13, textAlign: 'left' }}>
                          {t(it.text)}
                        </p>
                      )}
                    </CustomStyledLink>
                  )}
                </React.Fragment>
              ) : (
                <StyledScrollLink
                  onClick={() => handleMenu()}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  to={it?.to || '/'}
                  key={idx}
                >
                  {t(it.text)}
                </StyledScrollLink>
              )
            )}
            {isAdminPaths && (
              <>
                <StyledButton
                  onClick={() => {
                    setVisiblePercent(true)
                    handleMenu()
                  }}
                  style={{ fontSize: 14 }}
                >
                  {t('adminPercent.accrualInterest')}
                </StyledButton>
                <StyledButton
                  variant="black"
                  onClick={() => {
                    setCreateInvestPool(true)
                    handleMenu()
                  }}
                  style={{ fontSize: 14 }}
                >
                  Create Invest Pool
                </StyledButton>
              </>
            )}

            {PROJECT_NAME === 'Def' && (
              <LanguageBtn onClick={handleLangMenu}>
                <img src="/images/interface/header/language.svg" alt="" />
                {!isMenuSmall && <span>{t('common.language')}</span>}
              </LanguageBtn>
            )}

            {isAuth && isAdmin && (
              <CustomStyledLink
                data-admin-btn
                to={!isAdminPaths ? '/admin/analytics' : '/dashboard'}
                onClick={() => handleMenu()}
              >
                <AdminSvgIcon isActive={false} />
                {!isMenuSmall && (
                  <p style={{ textAlign: 'left' }}>
                    {t(isAdminPaths ? 'menu.backToUser' : 'menu.adminPanel')}
                  </p>
                )}
              </CustomStyledLink>
            )}

            {isAuth && (
              <ExitBtn onClick={() => setVisibleLogout(true)}>
                <ExitIcon />
                {!isMenuSmall && <span>{t('menu.logout')}</span>}
              </ExitBtn>
            )}

            <ThemeButtonsWrapper
              style={
                isMenuSmall
                  ? { flexDirection: 'column-reverse', transform: 'translateX(-5px)' }
                  : {}
              }
            >
              <ThemeButton onClick={toggleTheme} $isActive={isDarkTheme}>
                <img
                  src="/images/interface/header/moon.svg"
                  alt=""
                  style={{ filter: theme.colors.black === '#000' && 'invert(1)' }}
                />
              </ThemeButton>
              <ThemeButton onClick={toggleTheme} $isActive={!isDarkTheme}>
                <img src="/images/interface/header/sun.svg" alt="" />
              </ThemeButton>
            </ThemeButtonsWrapper>
          </ColumnMenu>

          {!isAuth && (
            <BtnWrapper>
              <StyledButton onClick={() => navigate('/register')}>
                {t('auth.reg_title')}
              </StyledButton>

              <StyledButton
                style={{
                  height: 'auto',
                  minHeight: 25,
                }}
                onClick={() => navigate('/login')}
                variant={'text'}
              >
                {t('auth.login')}
              </StyledButton>
            </BtnWrapper>
          )}
        </Wrapper>
      </Box>
      {isAdminPaths && visiblePercent && <AdminPercent setIsOpen={setVisiblePercent} />}
      {isAdminPaths && createInvestPool && (
        <AdminCreateInvestPool setIsOpen={setCreateInvestPool} />
      )}
      {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout} />}
      <LanguageModal openMenu={openLangModal} handleMenu={handleLangMenu} />
    </>
  )
}

export default observer(MenuModal)
