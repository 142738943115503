import {
  FlexColumnStart,
  FlexRow,
  StyledButton,
  FlexRowBetween,
  MarketTextBold,
  MarketText,
  InfoText,
  ErrorText,
  MarketTextBlack,
  TradeLabel,
  CloseModalButton,
} from '../../../style/projectComponents'
// import { StyledButton as StyledButtonTime } from '../../../style/projectTimeComponents'
import styled, { useTheme } from 'styled-components/macro'
import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { isAmountGood } from '../../WithdrawComponents/utils'
import { ValidationInputComponentMax } from '../ValidationInputComponentMax'
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage'
import { IInvestPoolReq } from '../../../service/investpool-api/models'
import { useMutation, useQuery } from 'react-query'
import InvestPoolApi from '../../../service/investpool-api/InvestPoolApi'
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore'
import SelectCurrencyPanel from '../SelectCurrencyPanel'
import AdminApi from '../../../service/admin-api/AdminApi'
import { getInvestPoolContent } from '../../../utils/getInvestPoolContent'
// import { PROJECT_NAME } from '../../../config/serverConstants'
import { InvestPoolDto } from '../../../service/api/generated'

// const StyledButton = PROJECT_NAME === 'Time' ? StyledButtonTime : StyledButtonDef
const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;

  @media (max-width: 480px) {
    width: 320px;
    label {
      left: 20px;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: ${({ theme }) => theme.colors.modalBg};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 13000;
`

const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: var(--8, 0.5rem);
  padding: 20px 25px;
  position: relative;
  width: clamp(320px, 90vw, 640px);
  overflow: auto;

  @media (max-width: 480px) {
    border-radius: 0.75rem;
  }
`
const Content = styled(FlexColumnStart)`
  align-items: center;

  @media (max-width: 480px) {
    align-items: center;
  }
`

const InnerWrapper = styled(FlexColumnStart)`
  margin-top: 16px;
  width: 100%;
  gap: 8px;
  @media (max-width: 480px) {
    width: 100%;
  }
`

const InvestLogo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 8px;
  object-fit: contain;
`

interface InvestModalProps {
  setIsOpen: (bool) => void
  setWithdrawOpen: (bool) => void
  investPool: InvestPoolDto
}

const InvestModal: FC<InvestModalProps> = ({ setIsOpen, investPool, setWithdrawOpen }) => {
  const { t } = useTranslation()

  const {
    register,
    control,
    clearErrors,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
  } = useForm()

  const ProfileStore = useContext(ProfileStorage)

  const [currencyId, setCurrencyId] = useState(0)
  const changeCurrencyId = (id: number) => setCurrencyId(id)
  const [userBalance, setUserBalance] = useState(0)

  useEffect(() => {
    setUserBalance(
      ProfileStore?.profile?.wallets?.find(
        it => it.currency === investPool.availableCurrencies?.[currencyId]
      )?.balance || 0
    )
  }, [currencyId])

  const setValueToMax = () => {
    if (userBalance > 0) {
      setValue('amount', userBalance)
    }
  }

  const investPoolTitles = [
    t('invest.totalInvested'),
    t('invest.yourLimit'),
    t('invest.yourInvestments'),
    t('invest.table.tokens'),
    t('invest.table.period'),
    t('invest.table.term'),
    t('invest.holdPeriod'),
  ]
  const investPoolContent = getInvestPoolContent(investPool, investPoolTitles)
  const logo = investPool.logoDownloadLink

  const GlobalModalStorage = useContext(GlobalModalStore)
  const investMutation = useMutation((data: IInvestPoolReq) =>
    InvestPoolApi.investInInvestmentPool(data)
  )

  const [currencyList, setCurrencyList] = useState(null)
  const currencyItems = currencyList?.currencies || []
  const tokenId = currencyItems.find(c => c.name === investPool.availableCurrencies[currencyId])?.id

  const GetCurrenciesList = useQuery(
    'currency_list',
    () =>
      AdminApi.getCurrencies({
        skip: 0,
        take: 30,
      }),
    {
      onSuccess: data => {
        setCurrencyList(data)
      },
    }
  )

  const onSubmit = data => {
    const investReq = {
      investPoolId: investPool.id,
      value: parseFloat(data.amount),
      tokenId: tokenId,
    }
    investMutation.mutate(investReq, {
      onSuccess: data => {
        GlobalModalStorage.makeVisible(true, t('common.success'))
        reset({
          amount: '',
        })
        setIsOpen(false)
      },
      onError: error => {
        GlobalModalStorage.makeVisible(false, t('common.error'))
      },
    })
  }

  const theme = useTheme()

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setIsOpen(false)
        }
      }}
    >
      <ContentWrapper>
        <Content>
          <FlexRowBetween style={{ alignItems: 'end' }}>
            <FlexRow>
              <InvestLogo src={logo} alt="" />
              <MarketTextBlack>{investPool.name}</MarketTextBlack>
            </FlexRow>
            <CloseModalButton onClick={() => setIsOpen(false)}>
              <img src={'/images/ic_cancel.svg'} />
            </CloseModalButton>
          </FlexRowBetween>

          <InnerWrapper>
            {investPoolContent.map((i, key) => (
              <FlexRowBetween key={i.title}>
                <MarketText>{i.title}</MarketText>
                <MarketTextBold>{i.value}</MarketTextBold>
              </FlexRowBetween>
            ))}

            <FlexColumnStart
              style={{
                width: '100%',
                marginTop: investPool?.availableCurrencies?.length > 0 ? 12 : 0,
              }}
            >
              {investPool?.availableCurrencies?.length > 0 && (
                <SelectCurrencyPanel
                  elements={investPool.availableCurrencies}
                  selectedId={currencyId}
                  selectElement={changeCurrencyId}
                />
              )}
              <InputWrapper style={{ marginBottom: 5, marginTop: 16 }}>
                <Controller
                  name="amount"
                  control={control}
                  rules={isAmountGood(userBalance)}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponentMax
                      setValueToMax={setValueToMax}
                      {...field}
                      type="text"
                      isError={!!errors['amount']}
                      label={t('withdraw.amount')}
                    />
                  )}
                />
              </InputWrapper>

              <InfoText style={{ marginTop: 3, marginBottom: 3 }} onClick={setValueToMax}>
                {t('withdraw.available')}{' '}
                <span style={{ color: `${({ theme }) => theme.colors.black}` }}>
                  {userBalance?.toFixed(6) || 0} {investPool.availableCurrencies[currencyId]}
                </span>
              </InfoText>

              <ErrorText hasError={!!errors['amount']}>
                {t(errors['amount']?.message?.toString())}
              </ErrorText>
            </FlexColumnStart>

            <StyledButton
              onClick={() => clearErrors()}
              variant="primary"
              type="submit"
              style={{ width: '100%', marginTop: 12, height: 44 }}
            >
              {t('invest.invest')}
            </StyledButton>
            <StyledButton
              variant="text"
              onClick={() => {
                setWithdrawOpen(true)
                setIsOpen(false)
              }}
              type="button"
              style={{ width: '100%', color: theme.colors.black }}
            >
              {t('withdraw.withdraw')}
            </StyledButton>
          </InnerWrapper>
        </Content>
      </ContentWrapper>
    </StyledForm>
  )
}

export default InvestModal
