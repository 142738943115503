import { useTheme } from 'styled-components/macro'

export const WithdrawalHistorySvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.17258 16.25H8.00425C5.01675 16.25 3.52341 16.25 2.59508 15.3042C1.66675 14.3584 1.66675 12.8359 1.66675 9.79171C1.66675 6.74754 1.66675 5.22504 2.59508 4.27921C3.52341 3.33337 5.01675 3.33337 8.00425 3.33337H11.1734C14.1609 3.33337 15.6551 3.33337 16.5834 4.27921C17.2976 5.00671 17.4617 6.07587 17.5001 7.91671"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.17258 16.25H8.00425C5.01675 16.25 3.52341 16.25 2.59508 15.3042C1.66675 14.3584 1.66675 12.8359 1.66675 9.79171C1.66675 6.74754 1.66675 5.22504 2.59508 4.27921C3.52341 3.33337 5.01675 3.33337 8.00425 3.33337H11.1734C14.1609 3.33337 15.6551 3.33337 16.5834 4.27921C17.2976 5.00671 17.4617 6.07587 17.5001 7.91671"
        stroke="url(#paint0_linear_2418_9941)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7083 14.4584L14.5833 13.7084V11.8334M10.8333 13.3334C10.8333 13.8258 10.9302 14.3135 11.1187 14.7684C11.3072 15.2234 11.5834 15.6368 11.9316 15.985C12.2798 16.3332 12.6932 16.6095 13.1482 16.7979C13.6032 16.9864 14.0908 17.0834 14.5833 17.0834C15.0757 17.0834 15.5633 16.9864 16.0183 16.7979C16.4733 16.6095 16.8867 16.3332 17.2349 15.985C17.5831 15.6368 17.8593 15.2234 18.0478 14.7684C18.2363 14.3135 18.3333 13.8258 18.3333 13.3334C18.3333 12.3388 17.9382 11.385 17.2349 10.6817C16.5316 9.97846 15.5778 9.58337 14.5833 9.58337C13.5887 9.58337 12.6349 9.97846 11.9316 10.6817C11.2283 11.385 10.8333 12.3388 10.8333 13.3334Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7083 14.4584L14.5833 13.7084V11.8334M10.8333 13.3334C10.8333 13.8258 10.9302 14.3135 11.1187 14.7684C11.3072 15.2234 11.5834 15.6368 11.9316 15.985C12.2798 16.3332 12.6932 16.6095 13.1482 16.7979C13.6032 16.9864 14.0908 17.0834 14.5833 17.0834C15.0757 17.0834 15.5633 16.9864 16.0183 16.7979C16.4733 16.6095 16.8867 16.3332 17.2349 15.985C17.5831 15.6368 17.8593 15.2234 18.0478 14.7684C18.2363 14.3135 18.3333 13.8258 18.3333 13.3334C18.3333 12.3388 17.9382 11.385 17.2349 10.6817C16.5316 9.97846 15.5778 9.58337 14.5833 9.58337C13.5887 9.58337 12.6349 9.97846 11.9316 10.6817C11.2283 11.385 10.8333 12.3388 10.8333 13.3334Z"
        stroke="url(#paint1_linear_2418_9941)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.2501 7.40745H4.16675M11.2501 7.40745C11.2501 7.73152 10.2531 8.33708 10.0001 8.56486M11.2501 7.40745C11.2501 7.08337 10.2531 6.47782 10.0001 6.25004"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.2501 7.40745H4.16675M11.2501 7.40745C11.2501 7.73152 10.2531 8.33708 10.0001 8.56486M11.2501 7.40745C11.2501 7.08337 10.2531 6.47782 10.0001 6.25004"
        stroke="url(#paint2_linear_2418_9941)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75008 10.3241H4.16675"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75008 10.3241H4.16675"
        stroke="url(#paint3_linear_2418_9941)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.50008 13.3334H4.16675"
        stroke="white"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.50008 13.3334H4.16675"
        stroke="url(#paint4_linear_2418_9941)"
        stroke-width="0.833333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9941"
          x1="-16.495"
          y1="-59.2317"
          x2="-0.0594286"
          y2="-61.0832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2418_9941"
          x1="2.23031"
          y1="-26.7448"
          x2="10.0484"
          y2="-27.4632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2418_9941"
          x1="-3.95825"
          y1="-4.96235"
          x2="2.94215"
          y2="-6.90278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2418_9941"
          x1="-1.0906"
          y1="5.48039"
          x2="3.00148"
          y2="3.75682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2418_9941"
          x1="0.343219"
          y1="8.48962"
          x2="3.54666"
          y2="7.50833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
