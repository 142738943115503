import { useTheme } from 'styled-components/macro'

export const ConvertSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z"
        stroke="url(#paint0_linear_4001_9740)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.08301 7.75H12.9163M7.08301 7.75C7.08301 7.16333 8.54134 6.25 8.54134 6.25M7.08301 7.75C7.08301 8.33667 8.54134 9.25 8.54134 9.25M12.9163 12.25H7.08301M12.9163 12.25C12.9163 11.6633 11.458 10.75 11.458 10.75M12.9163 12.25C12.9163 12.8367 11.458 13.75 11.458 13.75"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.08301 7.75H12.9163M7.08301 7.75C7.08301 7.16333 8.54134 6.25 8.54134 6.25M7.08301 7.75C7.08301 8.33667 8.54134 9.25 8.54134 9.25M12.9163 12.25H7.08301M12.9163 12.25C12.9163 11.6633 11.458 10.75 11.458 10.75M12.9163 12.25C12.9163 12.8367 11.458 13.75 11.458 13.75"
        stroke="url(#paint1_linear_4001_9740)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4001_9740"
          x1="-17.4511"
          y1="-79.0625"
          x2="-0.0777059"
          y2="-80.6591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4001_9740"
          x1="0.391831"
          y1="-30.0781"
          x2="6.49272"
          y2="-30.5142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
