import styled from 'styled-components/macro'

export const StyledBg = styled.div`
  background-color: var(--color-base);
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  * {
    box-sizing: border-box;
    color: var(--color-content);
  }

  p {
    margin: 0;
    padding: 0;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  .content {
    background-color: var(--color-base);
    color: var(--color-content);
    font-family: var(--font-regular);
    font-weight: normal;
    margin: 0 auto;
    z-index: 0;
    overflow: hidden;
  }

  @media screen and (max-width: 1280px) {
    .content {
      padding: 10px 20px;
    }
  }

  /* header */
  .header {
    color: var(--color-bg);
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px 0;
    max-width: var(--breakpoint-huge);
    z-index: 10000;
    position: relative;
  }

  @media screen and (max-width: 1280px) {
    .header {
      padding: 30px 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      padding: 10px 20px;
      background-color: var(--color-card-bg);
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 60px;
    position: relative;
    z-index: 100;
  }

  .nav-link {
    color: var(--color-content);
    background: linear-gradient(
      90.28deg,
      #ff8a00 var(--gradient-percent-1),
      #d449f7 var(--gradient-percent-2)
    );
    /* background: linear-gradient(90.28deg, #FF8A00 0%, #D449F7 100%); */
    padding: 6px 16px;
    border-radius: 9px;
    font-family: var(--font-regular);
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    transition:
      --gradient-percent-1 1s cubic-bezier(0.075, 0.82, 0.165, 1),
      --gradient-percent-2 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .nav-link:hover {
    --gradient-percent-1: -55.87%;
    --gradient-percent-2: 40.4%;
    color: var(--color-content);
  }

  @media screen and (hover: none) {
    .nav-link:active {
      background: var(--color-button-active);
      color: var(--color-content);
    }
  }

  .button-light {
    background: linear-gradient(
      84.75deg,
      var(--color-button-light) -55.87%,
      var(--color-button-light-second) 40.4%
    );
    color: var(--color-bg);
    transition:
      color 1s cubic-bezier(0.075, 0.82, 0.165, 1),
      --color-button-light 1s cubic-bezier(0.075, 0.82, 0.165, 1),
      --color-button-light-second 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .button-light:hover {
    --color-button-light: #ff8a00;
    --color-button-light-second: #d449f7;
    color: var(--color-content);
  }

  .button-dark {
    background: linear-gradient(
      84.75deg,
      var(--color-button-dark) -55.87%,
      var(--color-button-dark-second) 40.4%
    );
    color: var(--color-content);
    transition:
      color 1s cubic-bezier(0.075, 0.82, 0.165, 1),
      --color-button-dark 1s cubic-bezier(0.075, 0.82, 0.165, 1),
      --color-button-dark-second 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .button-dark:hover {
    --color-button-dark: #ff8a00;
    --color-button-dark-second: #d449f7;
    color: var(--color-content);
  }

  .button-large {
    width: 232px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu {
    cursor: pointer;
  }

  .section-wrapper {
    position: relative;
    margin-bottom: 40px;
  }

  .section {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: var(--breakpoint-huge);
    margin: 0 auto;
    position: relative;
  }

  .section-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 100;
  }

  @media screen and (min-width: 768px) {
    .section-top {
      gap: 20px;
      margin-top: 100px;
      margin-bottom: 140px;
    }
  }

  @media screen and (min-width: 992px) {
    .section-top {
      margin-top: 100px;
      margin-bottom: 170px;
    }
  }

  .title {
    font-family: var(--font-title);
    margin: 0;
    padding: 0;
    z-index: 1;
    font-size: 55px;
    font-weight: 800;
    line-height: 80.3px;
    letter-spacing: 0.11em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 25px;
      font-weight: 600;
      line-height: 36.5px;
      letter-spacing: 0.11em;
      text-align: left;
      text-decoration-skip-ink: none;
    }
  }

  .title-top {
    max-width: 60%;
  }

  @media screen and (max-width: 768px) {
    .title-top {
      max-width: 100%;
    }
  }

  .subtitle {
    z-index: 1;
    font-family: var(--font-regular);
    font-size: 35px;
    font-weight: 600;
    line-height: 42.67px;
    letter-spacing: 0.11em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    .subtitle {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.38px;
      letter-spacing: 0.11em;
      text-align: left;
      text-decoration-skip-ink: none;
    }
  }

  @media screen and (max-width: 768px) {
    .min-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
      letter-spacing: 0.11em;
    }
  }

  .large-text {
    z-index: 1;
    font-size: 24px;
    line-height: 28px;
    font-family: var(--font-regular);
    font-weight: normal;
  }

  @media screen and (max-width: 768px) {
    .large-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: 0.05em;
      text-align: left;
      text-decoration-skip-ink: none;
    }
  }

  .bold {
    font-weight: 700;
  }

  .extra-bold {
    font-weight: 800;
  }

  .medium-text {
    z-index: 1;
    font-family: var(--font-regular);
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    letter-spacing: 0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  @media screen and (max-width: 768px) {
    .medium-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      letter-spacing: 0.05em;
      text-align: left;
      text-decoration-skip-ink: none;
    }
  }

  .small-text {
    z-index: 1;
    font-size: 12px;
    line-height: 14px;
    font-family: var(--font-regular);
    font-weight: normal;
  }

  .large-text-top {
    max-width: 40%;
    font-size: 18px;
    line-height: 21px;
  }

  @media screen and (max-width: 768px) {
    .large-text-top {
      font-size: 16px;
      line-height: 19px;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .nav-link-large {
    z-index: 1;
    display: flex;
    gap: 86px;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    max-width: 60%;
  }

  .nav-link-large span {
    color: var(--color-base);
  }

  @media screen and (max-width: 600px) {
    .nav-link-large {
      width: 90%;
      align-self: center;
      margin-right: 10px;
      max-width: 100%;
    }
  }

  .phones-planet {
    position: absolute;
    z-index: 0;
    top: -160px;
    right: -65px;
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .phones-planet {
      align-self: center;
      position: static;
      width: 378px;
    }
  }

  .stone-top {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .stone-top {
      display: none;
    }
  }

  .stone-bottom {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .stone-bottom {
      left: 120px;
      width: 300px;
    }
  }

  .stone-right {
    position: absolute;
    z-index: 0;
    top: 60px;
    right: -20px;
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .stone-right {
      top: 300px;
      width: 150px;
    }
  }

  .stone-left {
    position: absolute;
    z-index: 0;
    top: 430px;
    left: -20px;
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .stone-left {
      width: 300px;
      top: 350px;
    }
  }

  .section-history {
    z-index: 100;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: var(--breakpoint-desktop);
    gap: 30px;
    margin: 0 auto 100px;
    width: 100%;
    padding: 52px 75px;
    /* -moz-border-image: -moz-linear-gradient(90deg, #000000 0%, #8152B1 102.04%);
  -webkit-border-image: -webkit-linear-gradient(90deg, #000000 0%, #8152B1 102.04%);
  border-image: linear-gradient(to right, #000000 0%, #8152B1 102.04%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background:
  linear-gradient(#0A0A0A, #0A0A0A) padding-box,
  linear-gradient(to right, #000000 0%, #8152B1 102.04%) border-box;
  border: 1px solid transparent; */
    border-radius: 26px;
    border: 1px solid #432a5c;
    backdrop-filter: blur(19.8999996185px);
  }

  @media screen and (max-width: 768px) {
    .section-history {
      margin: 30px auto 0;
      gap: 10px 30px;
      justify-content: center;
      align-items: center;
      padding: 30px 0 10px;
    }
  }

  .history-block {
    /* width: 270px; */
  }

  @media screen and (max-width: 768px) {
    .history-block {
      height: 70px;
      width: 110px;
    }
  }

  .hr {
    display: block;
    margin: 80px auto;
    width: clamp(300px, 100%, 800px);
  }

  @media screen and (max-width: 768px) {
    .hr {
      margin: 40px auto;
    }
  }

  .title-history {
    font-size: 33px;
    font-weight: 600;
    line-height: 40.23px;
    letter-spacing: 0.11em;
    text-align: left;
    text-decoration-skip-ink: none;
    margin: 0 0 10px;
  }

  @media screen and (max-width: 768px) {
    .title-history {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 24.38px;
      letter-spacing: 0.11em;
      text-align: left;
      text-decoration-skip-ink: none;
    }
  }

  .large-text-history {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    .large-text-history {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-align: left;
      text-decoration-skip-ink: none;
    }
  }

  .section-work-head {
    max-width: var(--breakpoint-desktop);
    margin: 80px auto 30px;
  }

  @media screen and (max-width: 768px) {
    .section-work-head {
      margin: 50px auto 25px;
    }
  }

  .section-work {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin: 20px auto;
    max-width: var(--breakpoint-desktop);
  }

  @media screen and (max-width: 768px) {
    .section-work {
      gap: 10px;
      padding-right: 20px;
      justify-content: center;
      max-width: 100%;
    }
  }

  .title-work {
    margin: 40px 0 10px;
    font-size: 45px;
    line-height: 68px;
    position: relative;
    z-index: 1000;
    color: var(--color-content);
  }

  @media screen and (max-width: 768px) {
    .title-work {
      font-size: 20px;
      font-weight: 600;
      line-height: 29.2px;
      letter-spacing: 0.11em;
      text-align: left;
      text-decoration-skip-ink: none;
      margin: 0 0 10px;
    }
  }

  .center-text {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .center-text {
      text-align: left;
    }
  }

  .work-block {
    width: 330px;
    height: 635px;
    border-radius: 36px;
    background-color: var(--color-container);
    backdrop-filter: blur(19.8999996185px);
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media screen and (max-width: 600px) {
    .work-block {
      width: 100vw;
      height: fit-content;
    }
  }

  .work-phone {
    align-self: center;
  }

  .large-text-work {
    font-size: 16px;
    line-height: 19px;
    font-family: var(--font-regular);
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    .large-text-work {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.63px;
      letter-spacing: 0.05em;
      margin-bottom: 30px;
    }
  }

  .mini-link {
    color: var(--color-content);
    text-decoration: none;
    width: 90px;
    height: 20px;
    font-size: 27px;
  }

  @media screen and (max-width: 768px) {
    .mini-link {
      font-size: 16px;
      font-weight: 500;
      line-height: 13.44px;
      letter-spacing: 0.11em;
    }
  }

  .circle {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: var(--color-light-container);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-title);
    font-size: 27px;
  }

  .work-block-title {
    font-size: 20px;
    line-height: 29px;
    font-family: var(--font-title);
    margin: 22px 0 10px;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    .work-block-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 23.36px;
      letter-spacing: 0.11em;
    }
  }

  .section-venture {
    z-index: 100;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: var(--breakpoint-desktop);
    gap: 10px;
    margin: 0 auto 20px;
    width: 100%;
    padding: 52px 75px;
    border-radius: 26px;
    border: 1px solid #432a5c;
    backdrop-filter: blur(19.8999996185px);
  }

  @media screen and (max-width: 768px) {
    .section-venture {
      margin: 30px auto 0;
      justify-content: space-evenly;
      padding: 20px 15px;
    }
  }

  .title-venture {
    font-size: 30px;
    line-height: 45px;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    .title-venture {
      font-size: 14px;
      font-weight: 600;
      line-height: 20.44px;
    }
  }

  .large-text-venture {
    font-size: 18px;
    line-height: 33px;
    font-weight: normal;
    margin: 5px 0;
    padding: 0;
    max-width: 75%;
  }

  .large-text-venture:first-child {
    align-self: flex-start;
  }

  @media screen and (max-width: 768px) {
    .large-text-venture {
      font-size: 10px;
      font-weight: 500;
      line-height: 12.19px;
      letter-spacing: 0.05em;
      align-self: flex-start;
      max-width: 100%;
      margin: 0;
    }
  }

  .venture {
    margin: 100px auto 60px;
  }

  .card {
    border-radius: 36px;
    background-color: var(--color-container);
    padding: 10px 26px;
  }

  @media screen and (max-width: 600px) {
    .card {
      border-radius: 20px;
      padding: 10px 10px;
    }
  }

  .card-venture {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    background-color: unset;
  }

  @media screen and (max-width: 768px) {
    .card-venture {
      gap: 10px;
      padding: 0;
    }
  }

  .knot-left {
    position: absolute;
    z-index: 0;
    top: -60px;
    left: -20px;
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .knot-left {
      width: 150px;
      top: -80px;
    }
  }

  .knot-right {
    position: absolute;
    z-index: 0;
    top: 60px;
    right: -20px;
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .knot-right {
      top: 70px;
      width: 150px;
    }
  }

  .section-invest {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }

  @media screen and (max-width: 768px) {
    .section-invest {
      gap: 8px;
    }
  }

  .card-invest {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    background-color: var(--color-card-bg);
    gap: 24px;
    border-radius: 26px;
    max-width: var(--breakpoint-desktop);
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    .card-invest {
      padding: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .even-card {
      justify-content: flex-end;
    }
  }

  .title-invest {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 800;
    line-height: 29.24px;
    letter-spacing: 0.11em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  @media screen and (max-width: 768px) {
    .title-invest {
      font-size: 14px;
      font-weight: 600;
      line-height: 20.44px;
      letter-spacing: 0.11em;
    }
  }

  .large-text-invest {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: 0.05em;
    text-align: left;
  }

  @media screen and (max-width: 992px) {
    .large-text-invest {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.05em;
    }
  }

  .nav-link-invest {
    z-index: 1;
    display: flex;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    max-width: 60%;
    background: linear-gradient(
      84.75deg,
      var(--color-button-light) -55.87%,
      var(--color-button-light-second) 40.4%
    );
    color: var(--color-base);
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width: 992px) {
    .nav-link-invest {
      max-width: 100%;
      gap: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    .nav-link-invest {
      padding: 0px 7px;
    }
  }

  .nav-link-invest span {
    color: var(--color-base);
  }

  .card-invest-right-img {
    border-radius: 0 36px 36px 0;
    display: block;
    height: 227px;
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .card-invest-right-img {
      position: absolute;
      right: 0;
      height: 157px;
      border-radius: 0 20px 20px 0;
    }
  }

  .card-invest-left-img {
    border-radius: 36px 0 0 36px;
    display: block;
    height: 251px;
    object-fit: fill;
  }

  @media screen and (max-width: 1200px) {
    .card-invest-left-img {
      /* max-height: 100%; */
    }
  }

  @media screen and (max-width: 768px) {
    .card-invest-left-img {
      border-radius: 20px 0 0 20px;
      position: absolute;
      left: 0;
      height: 157px;
    }
  }

  .card-invest-left-text {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px 20px 30px;
    z-index: 10;
  }

  @media screen and (max-width: 768px) {
    .card-invest-left-text {
      min-height: 157px;
      padding: 7px 5px;
      width: 65%;
    }
  }

  .card-invest-right-text {
    min-height: 251px;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0px 20px 0px;
    z-index: 10;
  }

  @media screen and (max-width: 768px) {
    .card-invest-right-text {
      min-height: 157px;
      padding: 7px 5px;
      width: 61%;
    }
  }

  .section-caption {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    margin: 40px auto;
    width: 100%;
    max-width: var(--breakpoint-desktop);
  }

  @media screen and (max-width: 768px) {
    .section-caption {
      justify-content: center;
      margin: 20px auto;
    }
  }

  .caption-block {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-card-bg);
    width: 31.5%;
    text-decoration: none;
    border-radius: 12px;
    color: var(--color-content);
    overflow: hidden;
    padding-bottom: 20px;
  }

  @media (min-width: 481px) and (max-width: 1200px) {
    .caption-block {
      width: 48%;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 480px) {
    .caption-block {
      width: 100%;
    }
  }

  .caption-block:hover .caption-img {
    transform: scale(1.1);
  }

  .caption-title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    padding: 10px 0 5px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 80px;
  }

  .tags-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 15px;
    margin-bottom: 20px;
    height: 60px;
  }

  .tag {
    font-size: 11px;
    background: #202020;
    color: var(--color-content);
    padding: 6px 8px;
    border-radius: 9px;
    font-weight: 500;
  }

  .caption-text {
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .caption-img {
    width: 100%;
    height: 140px;
    transition: 0.3s linear;
    object-fit: cover;
    transform-origin: center bottom;
  }

  .section-start {
    z-index: 100;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    max-width: var(--breakpoint-desktop);
    gap: 30px;
    margin: 100px auto 100px;
    min-height: 433px;
    width: 100%;
    padding: 0 20px;
    border: 1px solid #432a5c;
    border-radius: 26px;
    background-image: url(../images/circles.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

  @media screen and (max-width: 480px) {
    .section-start {
      margin: 50px auto 0;
      gap: 10px;
      justify-content: space-evenly;
      background-image: url(../images/circles-small.png);
      background-position: bottom;
      background-size: cover;
    }
  }

  .text-gradient {
    background: linear-gradient(90.28deg, #ff8a00 0%, #d449f7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .section-start-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    gap: 20px;
    width: 50%;
    padding: 20px 0;
  }

  @media screen and (max-width: 480px) {
    .section-start-left {
      width: 100%;
      gap: 10px;
    }
  }

  .section-start-right {
    align-self: flex-end;
  }

  @media screen and (max-width: 768px) {
    .section-start-right {
      width: 300px;
    }
  }

  @media screen and (max-width: 480px) {
    .section-start-right {
      width: 200px;
    }
  }

  .middle-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: 0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  @media screen and (max-width: 768px) {
    .middle-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.63px;
      letter-spacing: 0.05em;
    }
  }

  .section-faq {
    max-width: var(--breakpoint-desktop);
  }

  .select-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  .select-wrapper:last-child .section-faq-select {
    max-height: 100px;
  }

  @media screen and (max-width: 768px) {
    .select-wrapper:last-child .section-faq-select {
      max-height: 120px;
    }
  }

  .section-faq-select {
    width: 100%;
    padding: 16px 20px;
    border-radius: 16px;
    border: 1px solid #432a5c;
    backdrop-filter: blur(19.8999996185px);
    max-height: 80px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    .section-faq-select {
      max-height: 75px;
    }
  }

  .section-faq-select-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.11em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 480px) {
    .section-faq-select-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.5px;
      letter-spacing: 0.11em;
    }
  }

  .accordion-select {
    backdrop-filter: blur(19.8999996185px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120%;
    min-height: 80px;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
    position: absolute;
    top: 2px;
  }

  .section-faq-content-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    letter-spacing: 0.05em;
    text-align: left;
    text-decoration-skip-ink: none;
  }

  @media screen and (max-width: 768px) {
    .section-faq-content-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.5px;
      letter-spacing: 0.05em;
    }
  }

  .section-faq-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: 0.05em;
    text-align: left;
    text-decoration-skip-ink: none;
  }

  @media screen and (max-width: 768px) {
    .section-faq-content {
      font-size: 12px;
      font-weight: 300;
      line-height: 14.5px;
      letter-spacing: 0.05em;
    }
  }

  .select-arrow {
    display: block;
    padding: 4px 20px 14px;
    transition:
      --color-button-dark 1s cubic-bezier(0.075, 0.82, 0.165, 1),
      --color-button-dark-second 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    background: linear-gradient(
      90deg,
      var(--color-button-dark) 0%,
      var(--color-button-dark-second) 100%
    );
    border-radius: 10px;
  }

  .select-arrow img {
    transition: transform 0.3s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    .select-arrow {
      padding: 4px 12px 7px;
    }
  }

  .accordion-select:checked + .section-faq-select {
    max-height: 50em;
  }

  .accordion-select:checked + .section-faq-select .select-arrow {
    --color-button-dark: #ff8a00;
    --color-button-dark-second: #d449f7;
  }

  .accordion-select:checked + .section-faq-select .select-arrow img {
    transform: rotate(180deg);
  }

  .section-howto {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
    margin: 20px auto;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .section-howto {
      justify-content: center;
    }
  }

  .howto-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 36px;
    background-color: var(--color-container);
    width: 432px;
    min-height: 370px;
  }

  @media screen and (max-width: 470px) {
    .howto-block {
      width: calc(100vw - 20px);
      min-height: 275px;
    }
  }

  @media screen and (max-width: 390px) {
    .howto-block {
      width: 331px;
    }
  }

  .howto-block:last-child {
    width: 100%;
    min-height: 200px;
    flex-direction: row;
  }

  @media screen and (max-width: 1442px) {
    .howto-block:last-child {
      width: 870px;
    }
  }

  @media screen and (max-width: 1000px) {
    .howto-block:last-child {
      width: 432px;
      flex-direction: column;
    }
  }

  .howto-text-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .howto-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.18);
    padding: 14.5px 10px;
    border-radius: 38px;
  }

  .howto-nav-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.18);
    padding: 10px 14.5px;
    border-radius: 38px;
    height: 100%;
    min-height: 180px;
  }

  @media screen and (max-width: 1442px) {
    .howto-nav-vertical {
      min-height: 230px;
    }
  }

  .howto-title {
    font-size: 20px;
    line-height: 27px;
    padding: 0;
    margin: 0;
  }

  .howto-text {
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    font-weight: normal;
  }

  .circle-negative {
    background-color: var(--color-base);
    color: var(--color-content);
    opacity: 1;
  }

  .circle-shadow {
    box-shadow: 0px 0px 30px 0px rgb(18, 246, 255);
  }

  /* links */
  .section-links {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin: 30px auto 100px;
    width: var(--breakpoint-desktop);
    padding: 52px 75px;
    border-radius: 26px;
    border: 2px solid #432a5c;
    backdrop-filter: blur(19.8999996185px);
    position: relative;
    z-index: 100;
    /* -moz-border-image: -moz-linear-gradient(90deg, #000000 0%, #8152B1 102.04%);
  -webkit-border-image: -webkit-linear-gradient(90deg, #000000 0%, #8152B1 102.04%);
  border-image: linear-gradient(to right, #000000 0%, #8152B1 102.04%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background:
      linear-gradient(rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 1)) padding-box,
      linear-gradient(to right, #000000 0%, #8152B1 102.04%) border-box;
  border: 1px solid transparent; */
    /* &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-container);
      opacity: 0.8;
      filter: blur(2px);
      border-radius: 26px;
      z-index: -1;
  } */
  }

  @media screen and (max-width: 1024px) {
    .section-links {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      margin: 14px auto 50px;
      padding: 0;
    }
  }

  .section-socials-links {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 25px;
    margin: 20px auto 100px;
    width: 100%;
    position: relative;
    z-index: 100;
  }

  @media screen and (max-width: 768px) {
    .section-socials-links {
      margin: 0px auto 50px;
    }
  }

  @media screen and (max-width: 320px) {
    .section-socials-links {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 10px auto 50px;
    }
  }

  .link-block {
    text-decoration: none;
    color: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px;
    min-height: 116px;
  }

  @media screen and (max-width: 768px) {
    .link-block {
      min-height: 110px;
      justify-content: center;
      gap: 20px;
    }
  }

  .link-text {
    font-size: 18px;
    font-family: var(--font-title);
    text-decoration: underline;
  }

  .socials-link-text {
    font-size: 30px;
    font-family: var(--font-title);
    text-decoration: none;
    color: var(--color-content);
  }

  @media screen and (max-width: 768px) {
    .socials-link-text {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .socials-link {
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    .socials-link {
      text-align: left;
      gap: 4px;
    }
  }

  /* footer */
  .footer-wrapper {
    background-color: var(--color-container);
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .footer {
    background-color: var(--color-container);
    color: var(--color-content);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    padding: 50px 0px;
    max-width: var(--breakpoint-huge);
    position: relative;
  }

  @media screen and (max-width: 1280px) {
    .footer {
      padding: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .footer {
      background-color: var(--color-container);
      padding: 50px 10px;
      gap: 30px;
      flex-direction: column-reverse;
      justify-content: center;
      align-self: center;
      gap: 40px;
    }
  }

  .footer-block {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  @media screen and (max-width: 768px) {
    .footer-block {
      width: 100%;
      justify-content: center;
      flex-direction: column-reverse;
    }
  }

  .footer-top {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    .footer-top {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .footer-right {
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    .footer-bottom {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }
  }

  .footer-social {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  .stone-down {
    position: absolute;
    z-index: 0;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .stone-down {
      display: none;
    }
  }

  .bottom-wrapper {
    position: relative;
    z-index: 1;
  }

  .ellipse-bottom {
    position: absolute;
    width: 956px;
    height: 853px;
    right: 0;
    bottom: 200px;
  }

  /* common styles */
  .center {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .desktop {
      display: none;
    }
  }

  @media screen and (min-width: 769px) {
    .mobile {
      display: none;
    }
  }

  .self-center {
    align-self: center;
  }

  .full-width {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    .desktop-howto {
      display: none;
    }
  }

  @media screen and (min-width: 1001px) {
    .mobile-howto {
      display: none;
    }
  }

  .connect {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    height: 65px;
    width: 65px;
    background-color: var(--color-content);
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10000;
  }

  /*# sourceMappingURL=style.css.map */
`
