import { useTranslation } from 'react-i18next'
import { PROJECT_NAME } from '../../../config/serverConstants'
import {
  ContainerHead,
  Description,
  DescriptionWrapper,
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  GradientText,
  TextContainerBlur,
} from '../../../style/projectComponents'
import { HeaderImgDef } from '../../global/HeaderComponent/images/HeaderImgDef'
import { HeaderImgTime } from '../../global/HeaderComponent/images/HeaderImgTime'
import styled from 'styled-components/macro'
import { getProjectName } from '../../../utils/getProjectName'

const Images = {
  Def: HeaderImgDef,
  Time: HeaderImgTime,
}

const HistoryDescription = styled(Description)`
  font-size: 14px !important;
`

const HistoryCard = styled(FlexColumnStart)`
  width: 150px;
  gap: 10px;
`
const HistoryContainer = styled(DescriptionWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px 5px;
`

const DepositDescription = styled(HistoryDescription)`
  font-weight: 600;
`

const DepositDescriptionSmall = styled(DepositDescription)`
  font-size: 12px !important;
  line-height: 16px;
  font-weight: 400;
  margin-top: 5px;
`
const DepositRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 10px;
`
const DepositWrapper = styled(DescriptionWrapper)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Reward = () => {
  const { t } = useTranslation()

  return (
    <TextContainerBlur>
      <ContainerHead>
        {t('auth.presentation.reward')}{' '}
        <GradientText>{t('auth.presentation.rewardAmount')}</GradientText>
      </ContainerHead>
      <DescriptionWrapper>
        <Description>{t('auth.presentation.rewardText')}</Description>
      </DescriptionWrapper>
      <HistoryContainer>
        <HistoryCard>
          <ContainerHead>
            2015
            {/* <span>{t('auth.presentation.historyYear')}</span>. */}
          </ContainerHead>
          <HistoryDescription>{t('auth.presentation.historyText2015')}</HistoryDescription>
        </HistoryCard>
        <HistoryCard>
          <ContainerHead>1000+</ContainerHead>
          <HistoryDescription>{t('auth.presentation.historyTransaction')}</HistoryDescription>
        </HistoryCard>
        <HistoryCard>
          <ContainerHead>350%</ContainerHead>
          <HistoryDescription>{t('auth.presentation.historyYeld')}</HistoryDescription>
        </HistoryCard>
        <HistoryCard>
          <ContainerHead>15+</ContainerHead>
          <HistoryDescription>{t('auth.presentation.historyArea')}</HistoryDescription>
        </HistoryCard>
      </HistoryContainer>
      <DepositWrapper>
        <DepositRow>
          <img src={'./images/solar_money.svg'} alt="case" />
          <DepositDescription>
            {t('auth.presentation.depositRow1Text1')} <GradientText>$150</GradientText>{' '}
            {t('auth.presentation.depositRow1Text2')}{' '}
            <GradientText>{t('auth.presentation.depositRow1Text3')}</GradientText>
          </DepositDescription>
        </DepositRow>
        <DepositRow>
          <img src={'./images/hugeicon.svg'} alt="case" />
          <DepositDescription>
            {t('auth.presentation.depositRow2Text1')}
            <GradientText>{t('auth.presentation.depositRow2Text2')}</GradientText>
          </DepositDescription>
        </DepositRow>
        <DepositRow>
          <img src={'./images/mingcute.svg'} alt="case" />
          <DepositDescription>{t('auth.presentation.depositRow3Text1')}</DepositDescription>
        </DepositRow>
        <DepositRow>
          <img src={'./images/solar_hand.svg'} alt="case" />
          <DepositDescription>
            {t('auth.presentation.depositRow4Text1')}{' '}
            <GradientText>{t('auth.presentation.depositRow4Text2')}</GradientText>{' '}
            {t('auth.presentation.depositRow4Text3')}{' '}
            <GradientText>{t('auth.presentation.depositRow4Text4')}</GradientText>
          </DepositDescription>
        </DepositRow>
        <DepositRow>
          <img src={'./images/money-circle.svg'} alt="case" />
          <DepositDescription>
            {t('auth.presentation.depositRow5Text1')} <GradientText>8% 5% 3%</GradientText>
            {'\n'}
            <DepositDescriptionSmall>
              {t('auth.presentation.depositRow5Text2')}
              {t(getProjectName[PROJECT_NAME])}
              {t('auth.presentation.depositRow5Text3')}
            </DepositDescriptionSmall>
          </DepositDescription>
        </DepositRow>
      </DepositWrapper>
    </TextContainerBlur>
  )
}
