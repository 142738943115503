import { useTheme } from 'styled-components/macro'

export const AdminSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6666 18.3333V14.1667C16.6666 12.595 16.6666 11.81 16.1783 11.3217C15.6899 10.8333 14.9049 10.8333 13.3333 10.8333L9.99992 18.3333L6.66659 10.8333C5.09492 10.8333 4.30992 10.8333 3.82159 11.3217C3.33325 11.81 3.33325 12.595 3.33325 14.1667V18.3333"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6666 18.3333V14.1667C16.6666 12.595 16.6666 11.81 16.1783 11.3217C15.6899 10.8333 14.9049 10.8333 13.3333 10.8333L9.99992 18.3333L6.66659 10.8333C5.09492 10.8333 4.30992 10.8333 3.82159 11.3217C3.33325 11.81 3.33325 12.595 3.33325 14.1667V18.3333"
        stroke="url(#paint0_linear_2418_9912)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99992 12.5L9.58325 15.8333L9.99992 17.0833L10.4166 15.8333L9.99992 12.5ZM9.99992 12.5L9.16659 10.8333H10.8333L9.99992 12.5ZM12.9166 5.41667V4.58333C12.9166 4.20031 12.8411 3.82104 12.6946 3.46717C12.548 3.11331 12.3332 2.79178 12.0623 2.52094C11.7915 2.2501 11.4699 2.03526 11.1161 1.88868C10.7622 1.74211 10.3829 1.66667 9.99992 1.66667C9.6169 1.66667 9.23763 1.74211 8.88376 1.88868C8.52989 2.03526 8.20836 2.2501 7.93752 2.52094C7.66669 2.79178 7.45185 3.11331 7.30527 3.46717C7.15869 3.82104 7.08325 4.20031 7.08325 4.58333V5.41667C7.08325 5.79969 7.15869 6.17896 7.30527 6.53283C7.45185 6.88669 7.66669 7.20822 7.93752 7.47906C8.20836 7.7499 8.52989 7.96474 8.88376 8.11132C9.23763 8.25789 9.6169 8.33333 9.99992 8.33333C10.3829 8.33333 10.7622 8.25789 11.1161 8.11132C11.4699 7.96474 11.7915 7.7499 12.0623 7.47906C12.3332 7.20822 12.548 6.88669 12.6946 6.53283C12.8411 6.17896 12.9166 5.79969 12.9166 5.41667Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99992 12.5L9.58325 15.8333L9.99992 17.0833L10.4166 15.8333L9.99992 12.5ZM9.99992 12.5L9.16659 10.8333H10.8333L9.99992 12.5ZM12.9166 5.41667V4.58333C12.9166 4.20031 12.8411 3.82104 12.6946 3.46717C12.548 3.11331 12.3332 2.79178 12.0623 2.52094C11.7915 2.2501 11.4699 2.03526 11.1161 1.88868C10.7622 1.74211 10.3829 1.66667 9.99992 1.66667C9.6169 1.66667 9.23763 1.74211 8.88376 1.88868C8.52989 2.03526 8.20836 2.2501 7.93752 2.52094C7.66669 2.79178 7.45185 3.11331 7.30527 3.46717C7.15869 3.82104 7.08325 4.20031 7.08325 4.58333V5.41667C7.08325 5.79969 7.15869 6.17896 7.30527 6.53283C7.45185 6.88669 7.66669 7.20822 7.93752 7.47906C8.20836 7.7499 8.52989 7.96474 8.88376 8.11132C9.23763 8.25789 9.6169 8.33333 9.99992 8.33333C10.3829 8.33333 10.7622 8.25789 11.1161 8.11132C11.4699 7.96474 11.7915 7.7499 12.0623 7.47906C12.3332 7.20822 12.548 6.88669 12.6946 6.53283C12.8411 6.17896 12.9166 5.79969 12.9166 5.41667Z"
        stroke="url(#paint1_linear_2418_9912)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9912"
          x1="-11.9609"
          y1="-25.4948"
          x2="1.69088"
          y2="-27.7251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2418_9912"
          x1="0.392075"
          y1="-73.0078"
          x2="6.51673"
          y2="-73.2208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
