import React from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { getActiveMarketPair, getMarketDataByPair } from '../../utils/getActiveMarketPair'
import {
  MarketTextBold,
  FlexRow,
  FlexColumnStart,
  AppTextMini,
  MarketTextBlack,
  AppTextMiniBold,
} from '../../style/projectComponents'
import getColorByValue from '../../utils/getColorByValue'
import { formatNumber } from '../../utils/formatNumber'
import { useTranslation } from 'react-i18next'
import toLocaleNumber from '../../utils/toLocaleNumber'
import { ITicker } from '../../service/bybit-api/models'

const Block = styled(FlexRow)`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0;
  border: none;
  background: none;
  width: 100vw;
  text-decoration: none;
  gap: 32px;
  transform: translateY(2px);
`

const CurrencyImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 100%;
`
const ShowTradingListButton = styled(FlexRow)`
  cursor: pointer;
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
`
const PairInfoBlock = styled(FlexRow)`
  gap: 32px;
  @media (max-width: 600px) {
    align-items: start;
    flex-direction: column;
    gap: 12px;
  }
`
const PairInfo = styled(FlexColumnStart)`
  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
  }
`

interface MarketHeaderProps {
  tickerList: ITicker[] | null
  activePair: string
  openTradeList: any
}

export const MarketHeader: React.FC<MarketHeaderProps> = ({
  tickerList,
  activePair,
  openTradeList,
}) => {
  const { t } = useTranslation()

  const marketPair = getActiveMarketPair(activePair)
  const pairSymbol = marketPair?.symbol || ''
  const pairName = marketPair.pair
  const tokenPriceData = getMarketDataByPair(tickerList, activePair)

  // icon
  const icon = `/images/coins/${pairSymbol.toLowerCase()}.${pairSymbol === 'UNI' ? 'jpg' : 'png'}`
  // price
  const tokenPrice = (tokenPriceData?.lastPrice && toLocaleNumber(tokenPriceData?.lastPrice)) || 0
  const priceLow24h = tokenPriceData?.lowPrice24h || 0
  const priceHigh24h = tokenPriceData?.highPrice24h || 0
  // price percent
  const priceChangePercent = tokenPriceData?.price24hPcnt * 100 || 0
  const priceChangeString = `${
    Number(priceChangePercent) >= 0 ? '+' : ''
  }${Number(priceChangePercent).toFixed(2)}`
  // volume
  const totalVolume = tokenPriceData?.volume24h || 0
  const turnover24h = tokenPriceData?.turnover24h || 0
  const theme = useTheme()

  return (
    <Block>
      <FlexRow style={{ marginLeft: 16 }}>
        <CurrencyImage src={icon} alt="" />
        <MarketTextBlack>{pairName}</MarketTextBlack>
        <ShowTradingListButton onClick={openTradeList}>
          <img
            src="/images/interface/arrow-bottom.png"
            alt=""
            style={{
              marginLeft: 8,
              width: 12,
              filter: theme.colors.black === '#fff' && 'invert(1)',
            }}
          />
        </ShowTradingListButton>
      </FlexRow>
      <FlexColumnStart>
        <MarketTextBold>{toLocaleNumber(Number(tokenPrice))}</MarketTextBold>
        <MarketTextBold style={{ color: getColorByValue(priceChangePercent) }}>
          {priceChangeString} %
        </MarketTextBold>
      </FlexColumnStart>
      <PairInfoBlock>
        <PairInfo>
          <AppTextMini>{t('market.low24')}</AppTextMini>
          <AppTextMiniBold>{priceLow24h}</AppTextMiniBold>
        </PairInfo>
        <PairInfo>
          <AppTextMini>{t('market.high24')}</AppTextMini>
          <AppTextMiniBold>{priceHigh24h}</AppTextMiniBold>
        </PairInfo>
        <PairInfo>
          <AppTextMini>{t('market.volume')}</AppTextMini>
          <AppTextMiniBold>{formatNumber(totalVolume)}</AppTextMiniBold>
        </PairInfo>
        <PairInfo>
          <AppTextMini>{t('market.turnover24h')}</AppTextMini>
          <AppTextMiniBold>{formatNumber(turnover24h)}</AppTextMiniBold>
        </PairInfo>
      </PairInfoBlock>
    </Block>
  )
}
