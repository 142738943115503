import React, { createContext, useContext, useState } from 'react'

const GlobalStateContext = createContext(null)

export const GlobalMenuSizeProvider = ({ children }) => {
  const [menuSize, setMenuSize] = useState(220) // начальное состояние (px)

  return (
    <GlobalStateContext.Provider value={{ menuSize, setMenuSize }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

// Хук для использования контекста
export const useGlobalMenuSizeState = () => useContext(GlobalStateContext)
