import React from 'react'
import styled from 'styled-components/macro'
import { FlexColumn, Modal } from '../../../style/projectComponents'
import { ITicker } from '../../../service/bybit-api/models'
import { TradingPairsComponent } from '../TradingPairsComponent'

const Block = styled(FlexColumn)<{ isOpened: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 320px;
  height: 970px;
  justify-content: start;
  background: ${({ theme }) => theme.colors.gray050};
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};

  @media (max-width: 800px) {
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    top: 25px;
    animation: slideUp 0.5s forwards;
    transform: translateY(100%); /* Начальное состояние - за пределами видимости */
    @keyframes slideUp {
      from {
        transform: translateY(100%); /* Начальная позиция */
      }
      to {
        transform: translateY(0); /* Конечная позиция */
      }
    }
  }
`

const ModalWrapper = styled(Modal)<{ isOpened: boolean }>`
  background: none;
  justify-content: center;
  flex-direction: column;
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
  @media (max-width: 800px) {
    background: ${({ theme }) => theme.colors.modalBg};
  }
`

interface TradingPairsListModalProps {
  tradingPair: string
  tickerList: ITicker[] | null
  isOpened: boolean
  openTradeList: any
}

export const TradingPairsListModal: React.FC<TradingPairsListModalProps> = ({
  tradingPair,
  tickerList,
  isOpened,
  openTradeList,
}) => {
  return (
    <ModalWrapper
      isOpened={isOpened}
      onClick={e => {
        if (e.currentTarget === e.target) {
          if (isOpened) {
            openTradeList()
          }
        }
      }}
    >
      <Block isOpened={isOpened}>
        <TradingPairsComponent tradingPair={tradingPair} tickerList={tickerList} />
      </Block>
    </ModalWrapper>
  )
}
