import { sub } from 'date-fns'
import { de } from 'date-fns/locale'
import { title } from 'process'

export const en = {
  lg: 'en',
  common: {
    firstName: 'First Name',
    lastName: 'Last Name',
    balance: 'Balance',
    startingBalance: 'Starting balance',
    operationDate: 'Date of operation',
    createdOn: 'Date of creation',
    updatedOn: 'Date of execution',
    operationActorEmail: 'Email',
    walletOwnerEmail: 'Email',
    userName: 'Email',
    balanceDiff: 'Balance Diff',
    balanceDiffInUsd: 'USD Balance Diff',
    balanceDiffPercent: 'Percent',
    type: 'Operation type',
    status: 'Status',
    approved: 'Accepted',
    undefined: 'Unknown',
    notApproved: 'Pending',
    rejected: 'Rejected',
    cancelled: 'Canceled',
    withdrawalRequest: 'Withdrawal request',
    withdrawalAmount: 'Amount',
    deposit: 'Deposit',
    accrualOfInterest: 'Accrual of interest',
    forgotPassword: 'Forgot password?',
    success: 'Successful',
    error: 'Something went wrong',
    imageSizeOrTypeError: 'Wrong image size or type',
    withdrawErrorNotification: 'Withdraw {{amount}} was canceled',
    withdrawSuccessNotification: 'Withdraw {{amount}}',
    accepted: 'Accepted',
    errorModal: 'Error',
    next: 'Next',
    prev: 'Previous',
    id: 'Id',
    name: 'Name',
    coinGeckoId: 'Coingecko Id',
    usdPrice: 'USD price',
    currency: 'Currency',
    actions: 'Actions',
    copySuccess: 'Successfully copied',
    copyFailed: 'Copy error',
    referrals: 'Referrals',
    myReferrals: 'Referral',
    myPartners: 'My partners',
    email: 'email',
    level: 'Level',
    income: 'Income',
    profit: 'Profit',
    address: 'Address',
    evm: 'EVM',
    ownerId: 'Owner ID',
    totalReferrals: 'Total referrals',
    investPoolTransaction: 'Invest Pool Transaction',
    accrualOfInterestFromInvestPool: 'Accrual of interest from invest pool',
    exchange: 'Exchange',
    accrualOfInterestFromRefral: 'Accrual of interest from referral',
    commission: 'Commission',
    language: 'Language',
    loading: 'Loading',
    sec: 'sec',
    howItWorks: 'How it works',
    titleFull: 'Time.Investments ',
    defTitleFull: 'Defence.Investments ',
  },
  referral: {
    title1: 'About ',
    title2: 'referral program ',
    subTitle:
      'Invite partners and get rewarded for each person who fulfills the program condition.',
    refLink: 'Referral link',
    refLinkDesc: 'Copy the referral link to invite a partner to your team.',
    registration: 'Registration',
    retDesc: 'Register a partner`s personal account.',
    profit: 'Profit',
    profitDesc:
      'Receive 5% of each investment of your partner, during the whole term of the program! Your team can bring you up to 8% profit!',
  },
  forgotPassword: {
    title: 'Password Reset',
    letterSent: 'Email successfully sent',
    update: 'Update',
    newPass: 'New password',
  },
  settings: {
    title: 'Settings',
    save: 'Save',
    contacts: 'Contacts',
    inputs: {
      phone: {
        title: 'Phone Number',
        errors: {
          required: 'Phone number required',
        },
      },
      password: {
        title: 'Password',
        errors: {
          required: 'Password required',
          minLength: 'Minimum length of 8 characters',
          pattern: 'Password must contain at least 1 capital letter and 1 digit',
        },
      },
    },
  },
  adminAnalytics: {
    totalUsers: 'Total users',
    totalDeposits: 'Total deposits',
    currentInvestPoolsIncome: 'Current invest pools income',
    tvl: 'TVL',
    depositsAmount: 'Amount of deposits',
    depositsFlow: 'Inflow of deposits',
    balanceSum: 'Amount of balances',
    registration: 'Registrations',
    quantity: 'Number',
    users: 'Users',
  },
  adminUserDetail: {
    commonInfo: 'General Information',
    save: 'Save',
    name: {
      placeholder: 'Enter name',
      label: 'Full name',
    },
    balances: {
      title: 'Deposit amounts',
      balancesZero: 'Empty balance',
      balancesFrom0To1000: 'from 0 to 1000',
      balancesFrom1000To5000: 'from 1000 to 5000',
      balancesFrom5000To10000: 'from 5000 to 10000',
      balancesFrom10000To20000: 'from 10,000 to 20,000',
      balancesFrom20000To50000: 'from 20,000 to 50,000',
      balancesOverThan50000: 'more than 50,000',
    },
    email: {
      placeholder: 'Enter email address',
      label: 'Email',
    },
    phoneNumber: {
      placeholder: 'Enter the phone number',
      label: 'Phone number',
    },
    birthDate: {
      placeholder: 'Enter birthdate',
      label: 'Birthdate',
    },
    sum: {
      label: 'Sum',
    },
    addBalance: 'Add balance',
    addPercent: 'Add percent',
    addCommission: 'User commission',
    commissionRule: 'from 0 to 100',
    graphBalance: 'Balance chart',
    initialBalance: 'Set initial balance',
    setWithdrawalBalance: 'Withdrawal balance',
    inputRule: 'from 0 to 1 billion with 18 decimal places',
  },
  adminWithdraw: {
    withdrawRequests: 'Withdrawal requests',
    transactionEmpty: 'There are no transactions',
    comment: 'Comment',
    accept: 'Accept',
    decline: 'Reject',
  },
  adminWithdrawHistory: {
    withdrawHistory: 'Withdrawal history',
  },
  adminPercent: {
    accrualInterest: 'Interest accrual',
    percent: 'Percentage',
    processus: 'In process',
    add: 'Accrue',
    errors: {
      max: 'Max 1000%',
    },
  },
  adminOperations: {
    operationHistory: 'History of operations',
  },
  menu: {
    about: 'About us',
    dashboard: 'Dashboard',
    deposit: 'Assets',
    marketplace: 'Marketplace',
    withdraw: 'Withdraw',
    settings: 'Settings',
    phone: 'Phone',
    email: 'Email',
    logout: 'Log out',
    exit: 'Exit',
    cancel: 'Cancel',
    exitConfirm: 'Are you sure you want to log out?',
    withdrawRequests: 'Withdrawal requests',
    withdrawHistory: 'Withdrawal history',
    historyOperations: 'History of operations',
    profit: 'Income',
    principles: 'Principles',
    plans: 'Plans',
    cooperation: 'Subscribe',
    adminPanel: 'Admin',
    markets: 'Markets',
    convert: 'Convert',
    referrals: 'Referrals',
    backToUser: 'Back to user',
    usersList: 'Users list',
  },
  withdraw: {
    title: 'Select the withdrawal method',
    eth_address: 'ETH address',
    trc_address: 'TRC address',
    btc_address: 'BTC address',
    amount: 'Amount',
    withdraw: 'Withdraw',
    withdrawProfit: 'Available profit',
    available: 'Available',
    copy: 'Copied',
    errors: {
      wallet: {
        required: 'The field should not be empty',
        pattern: 'Invalid address',
      },
      amount: {
        required: 'The field should not be empty',
        pattern: 'Enter a number',
        max: 'Cannot exceed the current balance',
        min: 'Minimum 0.000001',
      },
    },
  },
  assets: {
    totalAssets: 'Total assets',
    asset: 'Asset',
    balance: 'Balance',
    actions: 'Actions',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
  },
  deposit: {
    title: 'Choose a deposit method',
    pay_method: 'Your deposit address',
    select_network: 'Select network',
    description: {
      '1': '1. Important. Transfer only {{title}} to this deposit address.',
      '2': '2. Minimum deposit amount is 100 000 USDT',
      '3': '3. After the deposit, contact your manager',
    },
    contacts: 'Contacts',
    phone: 'Phone number',
  },
  market: {
    title: 'Market',
    description:
      'View the latest prices of digital assets, alongside their daily price change and market cap statistics.',
    crypto: 'Crypto',
    searchCrypto: 'Search crypto',
    price: 'Price',
    lastPrice: 'Price',
    h24: '24h change',
    low24: '24h low',
    high24: '24h high',
    volume: '24h volume',
    marketCap: 'Market cap',
    orderBook: 'Order book',
    turnover24h: '24h turnover',
    name: 'Name',
    favorites: 'Favorites',
    spot: 'Spot',
  },
  invest: {
    title: 'Investment products',
    description: 'Choose and invest in any active pool on our platform.',
    invest: 'Invest',
    yourInvestments: 'Your investments',
    totalInvested: 'Total invested',
    yourLimit: 'Your limit',
    holdPeriod: 'Hold period',
    details: 'Details',
    about: 'About',

    table: {
      title: 'Product',
      description: 'Description',
      tokens: 'Available tokens',
      estApr: 'Est APR',
      term: 'Term',
      period: 'Freq of accruals',
    },
  },
  dashboard: {
    balance: 'Total Balance',
    total_profit: 'Total profit',
    total_invest_profit: 'Total invest profit',
    current_invest_income: 'Current invest income',
    not_withdraw_profit: 'Profit available to withdrawal',
    current_invest_pool_balance: 'Invested in pools',
    available_invest_pool_balance: 'Available investments',
    withdrawProfit: 'Profit to be withdrawn',
    fund_balance: 'Total capital',
    prev_dividends: 'Previous dividends',
    chart_title: 'Balance chart in USD',
    operations: 'Operations history',
    date: 'Date',
    amount: 'Amount',
    operation: 'Operation',
    funding: 'Funding',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    empty_data: "You haven't performed any operations yet",
    divHistory: 'Dividends history',
    chart: {
      week1: '1W',
      month1: '1M',
      month3: '3M',
      year1: '1Y',
      all: 'All',
    },
    filter: {
      operationType: 'Operation type',
      all: 'All',
      deposit: 'Deposit',
      withdrawal: 'Withdraw',
      dividends: 'Dividends',
    },
  },
  auth: {
    title: 'Log in',
    reg_title: 'Sign up',
    email: 'Email',
    password: 'Password',
    retype_password: 'Repeat password',
    login: 'Log in',
    not_registered: 'Not registered yet?',
    already_has: 'Already have an account?',
    create_acc: 'Create an account',
    name: 'Firstname',
    surname: 'Lastname',
    middlename: 'Middlename',
    phone: 'Phone',
    sendCode: 'Send code',
    codeSent: 'Code sent',
    successCode: 'Code sent successfully',
    newCodeCanBeSentIn: 'The new code can be sent in',
    code: 'Code from Email',
    continue: 'Continue',
    birthday: 'Date of birth',
    noUsCitizen:
      'I certify that I am not a US citizen (as defined by the US Securities Act of 1933).',
    iConfirm: 'By continuing I agree with',
    and: 'and',
    twoFa: '2FA',
    enableTwoFa: 'Enable 2FA',
    disableTwoFa: 'Disable 2FA',
    enterYour2fa: 'Enter your 2fa code',
    referralCode: 'Referral Code',
    referralLink: 'Referral Link',

    presentation: {
      welcome: 'Welcome to',
      title: 'Time Invest',
      defTitle: 'Defence Invest',
      description: 'Join on Time Investments now! Invest, follow and earn more.',
      defDescription: 'Join on Defence Investments now! Invest, follow and earn more.',
      description2:
        'Access to Exclusive Investment Opportunities in Pre-IPO, Venture Capital, Funds, Real Estate, and Cryptocurrencies for Investors of All Levels. Your investments are always at hand!',
      reward: 'Referral Rewards over',
      rewardAmount: '30,000',
      rewardText:
        'investment bonus from first affiliates, as well as 3% for friends they invite and 2% from the rest of your branch. Get rewards and upgrade to VIP status!',
      historyText2015: 'Work experience in the crypto market',
      historyYear: 'y',
      historyTransaction: 'Successful transactions',
      historyYeld: 'Average yield',
      historyArea: 'Areas of investment',
      depositRow1Text1: 'Deposit',
      depositRow1Text2: 'and make your',
      depositRow1Text3: 'first investment',
      depositRow2Text1: 'Receive up to 5% of investments of ',
      depositRow2Text2: 'invited partners',
      depositRow3Text1:
        'Referral program allows you to earn more than $3050 per month from your partners',
      depositRow4Text1: 'If your total team volume',
      depositRow4Text2: 'exceeds $300,000,',
      depositRow4Text3: 'you get an increased reward of 10% from the referral program monthly, get',
      depositRow4Text4: 'VIP status',
      depositRow5Text1: 'Improved referral system',
      depositRow5Text2:
        'All VIP members can receive such income due to the fact that the platform removes its commissions for them. ',
      depositRow5Text3: 'does not earn commissions from VIP clients.',
      neverMissTitle: 'Never miss a beat, with ',
      neverMissCard1: 'Online support 24/7',
      neverMissCard2: 'Buy an asset in just 2 clicks',
      neverMissCard2_2: 'Exclusive offers from $100 to $500,000',
      neverMissCard3: 'Accessible from any device (web, iOS, Android)',
      neverMissCard4: 'Personalized manager for clients from $100,000',
    },
    errors: {
      email: {
        required: 'The field cannot be empty',
        pattern: 'Enter the correct address',
      },
      password: {
        required: 'The field cannot be empty',
        minLength: 'Minimum length of 8 characters',
        pattern: 'Password must contain at least 1 capital letter and 1 digit',
        validate: 'Passwords do not match',
        serverError: 'Login error try again',
        serverRegError: 'Registration error try again',
        serverRegErrorUserExist: 'A user with this email or phone number already exists',
      },
      birthday: {
        required: 'The field cannot be empty',
        pattern: 'Enter in the format DD-MM-YYYY',
      },
      name: {
        required: 'The field cannot be empty',
      },
      surname: {
        required: 'The field cannot be empty',
      },
      middlename: {
        required: 'The field cannot be empty',
      },
      phone: {
        required: 'The field cannot be empty',
      },
    },
  },
  footer: {
    profit: 'Profitability',
    leaders: 'Market Leaders',
    principles: 'Values',
    strategy: 'Strategies',
    team: 'Team',
    plans: 'Plans',
    serts: 'Certificate',
    cooperation: 'Cooperation',
    aml: 'AML Policy',
    privacy: 'Privacy Notice',
    termsOfUse: 'Terms of use',
    subscription: 'Contract Form',
    juridic: 'Legal Address',
    addressJur: 'Intershore Chambers, PO Box 4342, Road Town, Tortola, British Virgin Islands.',
    mainOffice: 'Main Office',
    mainAddress: 'Azerbaijan, Baku, Neftchi Gurban 29, AZ1003, "BAIL Plaza", office 8.',
    allRights: 'All rights reserved.',
    menu: 'Menu',
    community: 'Community',
  },
  landing: {
    join: 'Try system',
    title: 'Revolutionize Your FinTech & BlockChain Future',
    description1:
      'Welcome to the forefront of financial technology innovation! Our powerful application as the cornerstone for creating a diverse range of groundbreaking applications.',
    description2:
      "Whether you're envisioning a cryptocurrency exchange, a centralized or crypto wallet, payment processing solution, or advanced asset analytics platform, our versatile framework is your key to unlocking endless possibilities.",
    whyChooseUs: {
      title: 'Why Choose Our Development System?',
      versatility: 'Versatility',
      versatilityDesc:
        'Our product provides the flexibility to tailor solutions for various fintech needs, ensuring a perfect fit for your unique requirements.',
      security: 'Security',
      securityDesc:
        'With a robust security architecture, rest easy knowing your financial transactions and data are fortified against potential threats.',
      scalability: 'Scalability',
      scalabilityDesc:
        "Whether you're a startup or an established enterprise, our development scales seamlessly to accommodate your growth and evolving demands.",
      innovation: 'Innovation',
      innovationDesc:
        'Stay ahead of the curve with our cutting-edge features and technology, setting the stage for your success in the dynamic world of fintech.',
    },
    explore: {
      title: 'Explore the Possibilities',
      one1: 'Crypto Exchange',
      one2: 'Launch your own secure and efficient cryptocurrency exchange platform, catering to the demands of the evolving digital asset landscape',
      two1: 'Crypto Wallet',
      two2: 'Launch your own secure and efficient cryptocurrency exchange platform, catering to the demands of the evolving digital asset landscape.',
      three1: 'Invest Fund',
      three2:
        'Establish and manage your own investment fund, empowering you to navigate the dynamic landscape of financial investments.',
      four1: 'Asset Analytics',
      four2:
        'Gain valuable insights into financial trends and market dynamics with our advanced asset analytics tools, empowering you to make informed decisions.',
    },
  },
}

export type En = typeof en
