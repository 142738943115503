import React, { useEffect } from 'react'
import { FlexColumn } from '../../style/projectComponents'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import PageHeader from './PageHeader'
import ConvertForm from './ConvertForm'
import PageWrapper from '../../components/global/PageWrapper'

const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  padding-bottom: 20px;
  padding-top: 0;
  @media screen and (max-width: 600px) {
    padding: 24px 12px;
    margin-top: 30px;
  }
`

const ConvertPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageWrapper>
      <StyledBg>
        <PageHeader />
        <ConvertForm />
      </StyledBg>
    </PageWrapper>
  )
}

export default observer(ConvertPage)
