import React, { useEffect } from 'react'
import { FlexColumn } from '../../style/projectComponents'
import styled from 'styled-components/macro'
import { observer } from 'mobx-react-lite'
import PageWrapper from '../../components/global/PageWrapper'

const StyledBg = styled(FlexColumn)`
  justify-content: start;
  align-items: start;
  width: 100vw;
  height: 100%;
  margin-top: 54px;
`

const InvestPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageWrapper>
      <StyledBg>
        <p>Invest</p>
      </StyledBg>
    </PageWrapper>
  )
}

export default observer(InvestPage)
