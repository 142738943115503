import { useTheme } from 'styled-components/macro'

export const AssetsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4539 10.8333C17.0391 9.99211 17.3924 9.01159 17.4782 7.99045C17.564 6.9693 17.3793 5.94358 16.9427 5.01651C16.506 4.08945 15.8329 3.29377 14.991 2.70955C14.1491 2.12534 13.1682 1.77321 12.1469 1.68861C11.1257 1.60402 10.1002 1.78994 9.17363 2.22766C8.24708 2.66538 7.45219 3.33946 6.86897 4.18205C6.28575 5.02463 5.93477 6.00598 5.85138 7.02732C5.76799 8.04866 5.95512 9.07395 6.39393 9.99998"
        stroke="url(#paint0_linear_4001_9735)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.365 9.72246V5.27746M11.6667 5.27746V4.16663M11.6667 10.8333V9.72246M10.365 7.49996H12.9683M12.9683 7.49996C13.4 7.49996 13.75 7.87329 13.75 8.33329V8.88913C13.75 9.34913 13.4 9.72246 12.9692 9.72246H9.58333M12.9683 7.49996C13.3992 7.49996 13.75 7.12663 13.75 6.66663V6.11079C13.75 5.65079 13.4 5.27746 12.9692 5.27746H9.58333M2.5 11.6666H4.49583C4.74083 11.6666 4.9825 11.7216 5.20167 11.8283L6.90333 12.6516C7.1225 12.7575 7.36417 12.8125 7.61 12.8125H8.47833C9.31833 12.8125 10 13.4716 10 14.285C10 14.3183 9.9775 14.3466 9.945 14.3558L7.8275 14.9416C7.44754 15.0466 7.04239 15.0099 6.6875 14.8383L4.86833 13.9583M10 13.75L13.8275 12.5741C14.1607 12.4719 14.5177 12.4775 14.8475 12.5902C15.1774 12.7029 15.4632 12.9169 15.6642 13.2016C15.9717 13.6266 15.8467 14.2366 15.3983 14.495L9.13583 18.1091C8.94001 18.2224 8.72317 18.2947 8.49853 18.3215C8.27389 18.3483 8.04614 18.329 7.82917 18.265L2.5 16.6833"
        stroke="url(#paint1_linear_4001_9735)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4001_9735"
          x1="6.0061"
          y1="-26.0667"
          x2="17.7298"
          y2="-25.9931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4001_9735"
          x1="2.69894"
          y1="-38.7064"
          x2="16.0978"
          y2="-38.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
