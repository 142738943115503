export const ExitIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 14.6875C12.4383 16.2308 11.1525 17.5408 9.43 17.4992C9.02917 17.4892 8.53333 17.3492 7.5425 17.07C5.15833 16.3975 3.08833 15.2667 2.59167 12.7342C2.5 12.27 2.5 11.7458 2.5 10.6975V9.30251C2.5 8.25501 2.5 7.73085 2.59167 7.26501C3.08833 4.73335 5.15833 3.60251 7.5425 2.93001C8.53417 2.65085 9.02917 2.51085 9.43 2.50085C11.1525 2.45918 12.4383 3.76918 12.5 5.31251M17.5 10H8.33333M17.5 10C17.5 9.41668 15.8383 8.32668 15.4167 7.91668M17.5 10C17.5 10.5833 15.8383 11.6733 15.4167 12.0833"
      stroke="#FF007A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
