import React from 'react'
import styled from 'styled-components/macro'
import { AppTitleNormal, FlexColumn } from '../../style/projectComponents'
import { useTranslation } from 'react-i18next'
import { FaqDropdown } from './FaqDropdown'

const Block = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 30px;
  align-items: start;
`

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation()
  const faqList = [
    {
      title: 'Algorithmic trading',
      content: [
        {
          subtitle: 'Test Test Test',
          text: 'test test test',
        },
      ],
    },
  ]

  return (
    <Block>
      <AppTitleNormal style={{ marginBottom: 20 }}>{t('common.howItWorks')}</AppTitleNormal>
      {faqList?.map(i => <FaqDropdown title={i.title} content={i.content} />)}
    </Block>
  )
}
