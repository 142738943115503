import React from 'react'
import styled from 'styled-components/macro'
import { PROJECT_NAME } from '../../../config/serverConstants'
import { Welcome } from '../Register/Welcome'
import { Reward } from '../Register/Reward'
import { NeverMiss } from '../Register/NeverMiss'

const LeftContent = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: auto;
  width: 50%;
  margin: 0 50px 50px;
  h2 {
    font-size: 26px;
  }

  p {
    font-size: 16px;
    max-width: 520px;
  }

  @media (max-width: 1024px) {
    align-self: center;
    width: 100%;
    padding: 20px 10px;
  }
`

const BackgroundStone = styled.img`
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  width: 100%;
  z-index: -1;
  max-width: 2000px;
  /* height: 100%;
  max-height: 190vh; */
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const LeftContentComponent: React.FC = () => {
  return (
    <LeftContent>
      <BackgroundStone src="/images/stone.png" alt="stone" />
      <Welcome />
      <Reward />
      <NeverMiss />
      {/* <ImagesContainer>
        {PROJECT_NAME === 'Time' && (
          <ImagePlanet src="/images/Time/planet.png" alt="planet" width={'700px'} />
        )}
        <ImagePromo src="/images/promo/cmc-app.png" alt="promo" />
      </ImagesContainer> */}
    </LeftContent>
  )
}

export default LeftContentComponent
