import React, { useEffect, useState } from 'react'
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  MarketText,
  MarketTextBlack,
  MarketTextBold,
  StyledBg,
  StyledButton,
  TradeLabel,
} from '../style/projectComponents'
import { observer } from 'mobx-react-lite'
import styled, { useTheme } from 'styled-components/macro'
import PageWrapper from '../components/global/PageWrapper'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { usePostApiInvestPoolGetInvestPoolDetails } from '../service/api/generated'
import InvestModal from '../components/global/modal/InvestModal'
import WithdrawFromInvestModal from '../components/global/modal/WithdrawFromInvestModal'
import { useDisableBodyScroll } from '../hooks/useDisableBodyScroll'
import { getInvestPoolContent } from '../utils/getInvestPoolContent'

const InvestLogo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 8px;
  object-fit: contain;
`

const TopBlock = styled(FlexRowBetween)`
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 20px;
  padding: 20px;
  width: 100%;
`
const BottomBlockLeft = styled(FlexColumnStart)`
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 20px;
  padding: 20px;
  width: 60%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const BottomBlockRight = styled(BottomBlockLeft)`
  width: 37%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const Tag = styled(MarketText)`
  font-size: 11px;
  background: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.black};
  padding: 0 16px;
  border-radius: 6px;
  height: 28px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`
const BottomSection = styled(FlexRowBetween)`
  width: 100%;
  gap: 16px;
  margin-top: 16px;
  flex-wrap: wrap;
`
const InvestPoolPage: React.FC = () => {
  const { id } = useParams()
  const { data, mutate } = usePostApiInvestPoolGetInvestPoolDetails()
  const investPool = data?.data

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (id) {
      mutate({ data: { id } })
    }
  }, [id, mutate])
  const { t } = useTranslation()

  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleWithdrawModal, setVisibleWithdrawModal] = useState(false)
  useDisableBodyScroll(visibleModal || visibleWithdrawModal)

  const investPoolTitles = [
    t('invest.totalInvested'),
    t('invest.yourLimit'),
    t('invest.yourInvestments'),
    t('invest.table.tokens'),
    t('invest.table.period'),
    t('invest.table.term'),
    t('invest.holdPeriod'),
  ]
  const investPoolContent = investPool ? getInvestPoolContent(investPool, investPoolTitles) : null

  return (
    <>
      <PageWrapper>
        <StyledBg style={{ marginBottom: 30 }}>
          <TopBlock>
            <FlexColumnStart style={{ gap: '20px' }}>
              <FlexRow>
                <InvestLogo src={investPool?.logoDownloadLink} alt="logo" />
                <MarketTextBlack>{investPool?.name}</MarketTextBlack>
              </FlexRow>
              <FlexColumnStart>
                <MarketText>{t('invest.yourInvestments')}</MarketText>
                <MarketTextBold>${investPool?.totalInvestedInUsd}</MarketTextBold>
              </FlexColumnStart>
            </FlexColumnStart>
            <FlexColumn style={{ alignItems: 'flex-end' }}>
              <FlexRow style={{ gap: 6, height: 60, alignItems: 'start', marginTop: 6 }}>
                {investPool?.tags?.map((tag, idx) => <Tag key={idx}>{tag}</Tag>)}
              </FlexRow>
              <FlexRow style={{ gap: '10px' }}>
                <StyledButton onClick={() => setVisibleModal(true)}>
                  {t('invest.invest')}
                </StyledButton>
                <StyledButton
                  variant="black"
                  onClick={() => {
                    setVisibleWithdrawModal(true)
                  }}
                  type="button"
                >
                  {t('withdraw.withdraw')}
                </StyledButton>
              </FlexRow>
            </FlexColumn>
          </TopBlock>

          <BottomSection>
            <BottomBlockLeft>
              <MarketTextBlack>{t('invest.about')}</MarketTextBlack>
              <FlexColumnStart style={{ width: '100%', marginTop: 16 }}>
                <img
                  src={investPool?.imageDownloadLink}
                  alt="logo"
                  style={{ width: '100%', marginBottom: 10 }}
                />
                {investPool?.description.split('\n').map((i, key) => {
                  return <TradeLabel key={key}>{i}</TradeLabel>
                })}
              </FlexColumnStart>
            </BottomBlockLeft>
            <BottomBlockRight>
              <MarketTextBlack style={{ marginBottom: 5 }}>{t('invest.details')}</MarketTextBlack>
              {investPoolContent?.map((i, key) => (
                <FlexRowBetween key={i.title}>
                  <MarketText>{i.title}</MarketText>
                  <MarketTextBold>{i.value}</MarketTextBold>
                </FlexRowBetween>
              ))}
            </BottomBlockRight>
          </BottomSection>
        </StyledBg>
      </PageWrapper>
      {visibleModal && (
        <InvestModal
          setWithdrawOpen={setVisibleWithdrawModal}
          setIsOpen={setVisibleModal}
          investPool={investPool}
        />
      )}
      {visibleWithdrawModal && (
        <WithdrawFromInvestModal
          setIsOpen={setVisibleWithdrawModal}
          setInvestOpen={setVisibleModal}
          investPool={investPool}
        />
      )}
    </>
  )
}

export default observer(InvestPoolPage)
