import { useTheme } from 'styled-components/macro'

export const MarketplaceSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3332 4.99996C13.3332 3.42829 13.3332 2.64329 12.8448 2.15496C12.3565 1.66663 11.5715 1.66663 9.99984 1.66663C8.42817 1.66663 7.64317 1.66663 7.15484 2.15496C6.6665 2.64329 6.6665 3.42829 6.6665 4.99996M1.6665 11.6666C1.6665 8.74413 1.6665 7.28329 2.42317 6.29996C2.56206 6.1194 2.71595 5.95496 2.88484 5.80663C3.80817 4.99996 5.1765 4.99996 7.9165 4.99996H12.0832C14.8232 4.99996 16.1923 4.99996 17.1148 5.80663C17.2837 5.95551 17.4376 6.11996 17.5765 6.29996C18.3332 7.28329 18.3332 8.74413 18.3332 11.6666C18.3332 14.5891 18.3332 16.05 17.5765 17.0333C17.4397 17.2127 17.285 17.3777 17.1148 17.5258C16.1915 18.3333 14.8232 18.3333 12.0832 18.3333H7.9165C5.17734 18.3333 3.80734 18.3333 2.88484 17.5266C2.71464 17.3782 2.55995 17.2129 2.42317 17.0333C1.6665 16.05 1.6665 14.5891 1.6665 11.6666Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3332 4.99996C13.3332 3.42829 13.3332 2.64329 12.8448 2.15496C12.3565 1.66663 11.5715 1.66663 9.99984 1.66663C8.42817 1.66663 7.64317 1.66663 7.15484 2.15496C6.6665 2.64329 6.6665 3.42829 6.6665 4.99996M1.6665 11.6666C1.6665 8.74413 1.6665 7.28329 2.42317 6.29996C2.56206 6.1194 2.71595 5.95496 2.88484 5.80663C3.80817 4.99996 5.1765 4.99996 7.9165 4.99996H12.0832C14.8232 4.99996 16.1923 4.99996 17.1148 5.80663C17.2837 5.95551 17.4376 6.11996 17.5765 6.29996C18.3332 7.28329 18.3332 8.74413 18.3332 11.6666C18.3332 14.5891 18.3332 16.05 17.5765 17.0333C17.4397 17.2127 17.285 17.3777 17.1148 17.5258C16.1915 18.3333 14.8232 18.3333 12.0832 18.3333H7.9165C5.17734 18.3333 3.80734 18.3333 2.88484 17.5266C2.71464 17.3782 2.55995 17.2129 2.42317 17.0333C1.6665 16.05 1.6665 14.5891 1.6665 11.6666Z"
        stroke="url(#paint0_linear_4001_9725)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99984 9.16659C9.079 9.16659 8.33317 9.72659 8.33317 10.4166C8.33317 11.1066 9.079 11.6666 9.99984 11.6666C10.9207 11.6666 11.6665 12.2266 11.6665 12.9166C11.6665 13.6066 10.9207 14.1666 9.99984 14.1666M9.99984 9.16659C10.7248 9.16659 11.3432 9.51409 11.5715 9.99992M9.99984 9.16659V8.33325M9.99984 14.1666C9.27484 14.1666 8.6565 13.8191 8.42817 13.3333M9.99984 14.1666V14.9999M4.99984 9.99992H1.6665M18.3332 9.99992H14.9998"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99984 9.16659C9.079 9.16659 8.33317 9.72659 8.33317 10.4166C8.33317 11.1066 9.079 11.6666 9.99984 11.6666C10.9207 11.6666 11.6665 12.2266 11.6665 12.9166C11.6665 13.6066 10.9207 14.1666 9.99984 14.1666M9.99984 9.16659C10.7248 9.16659 11.3432 9.51409 11.5715 9.99992M9.99984 9.16659V8.33325M9.99984 14.1666C9.27484 14.1666 8.6565 13.8191 8.42817 13.3333M9.99984 14.1666V14.9999M4.99984 9.99992H1.6665M18.3332 9.99992H14.9998"
        stroke="url(#paint1_linear_4001_9725)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4001_9725"
          x1="-17.4511"
          y1="-79.0625"
          x2="-0.0777059"
          y2="-80.6591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4001_9725"
          x1="-17.4511"
          y1="-23.9584"
          x2="-0.809373"
          y2="-27.7818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
