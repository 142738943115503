import { useTheme } from 'styled-components/macro'

export const HistoryOperationsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0824 15.8333H10.4158C8.0591 15.8333 6.87994 15.8333 6.14827 15.1008C5.41577 14.3692 5.41577 13.19 5.41577 10.8333V6.66666C5.41577 4.30999 5.41577 3.13082 6.14827 2.39916C6.87994 1.66666 8.05827 1.66666 10.4158 1.66666H11.5349C12.2166 1.66666 12.5566 1.66666 12.8633 1.79332C13.1691 1.91999 13.4108 2.16166 13.8924 2.64332L16.1066 4.85666C16.5883 5.33832 16.8291 5.57999 16.9558 5.88582C17.0824 6.19166 17.0824 6.53249 17.0824 7.21416V10.8333C17.0824 13.19 17.0824 14.3692 16.3499 15.1008C15.6183 15.8333 14.4383 15.8333 12.0824 15.8333Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0824 15.8333H10.4158C8.0591 15.8333 6.87994 15.8333 6.14827 15.1008C5.41577 14.3692 5.41577 13.19 5.41577 10.8333V6.66666C5.41577 4.30999 5.41577 3.13082 6.14827 2.39916C6.87994 1.66666 8.05827 1.66666 10.4158 1.66666H11.5349C12.2166 1.66666 12.5566 1.66666 12.8633 1.79332C13.1691 1.91999 13.4108 2.16166 13.8924 2.64332L16.1066 4.85666C16.5883 5.33832 16.8291 5.57999 16.9558 5.88582C17.0824 6.19166 17.0824 6.53249 17.0824 7.21416V10.8333C17.0824 13.19 17.0824 14.3692 16.3499 15.1008C15.6183 15.8333 14.4383 15.8333 12.0824 15.8333Z"
        stroke="url(#paint0_linear_2418_9925)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4991 2.08333V2.91666C12.4991 4.48833 12.4991 5.27333 12.9874 5.76166C13.4758 6.24999 14.2608 6.24999 15.8324 6.24999H16.6658M5.41577 4.16666C4.75273 4.16666 4.11685 4.43005 3.648 4.89889C3.17916 5.36774 2.91577 6.00362 2.91577 6.66666V13.3333C2.91577 15.69 2.91577 16.8692 3.64827 17.6008C4.37994 18.3333 5.55827 18.3333 7.91577 18.3333H12.0824C12.7455 18.3333 13.3814 18.0699 13.8502 17.6011C14.319 17.1323 14.5824 16.4964 14.5824 15.8333M8.33327 9.16666H11.6666M8.33327 12.5H14.1666"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4991 2.08333V2.91666C12.4991 4.48833 12.4991 5.27333 12.9874 5.76166C13.4758 6.24999 14.2608 6.24999 15.8324 6.24999H16.6658M5.41577 4.16666C4.75273 4.16666 4.11685 4.43005 3.648 4.89889C3.17916 5.36774 2.91577 6.00362 2.91577 6.66666V13.3333C2.91577 15.69 2.91577 16.8692 3.64827 17.6008C4.37994 18.3333 5.55827 18.3333 7.91577 18.3333H12.0824C12.7455 18.3333 13.3814 18.0699 13.8502 17.6011C14.319 17.1323 14.5824 16.4964 14.5824 15.8333M8.33327 9.16666H11.6666M8.33327 12.5H14.1666"
        stroke="url(#paint1_linear_2418_9925)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9925"
          x1="-7.96658"
          y1="-66.9531"
          x2="4.22769"
          y2="-67.876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2418_9925"
          x1="-12.8563"
          y1="-76.6276"
          x2="1.51097"
          y2="-77.7448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
