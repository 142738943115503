import { useTheme } from 'styled-components/macro'

export const ReferralsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6668 18.3334V14.1667C16.6668 12.595 16.6668 11.81 16.1785 11.3217C15.6902 10.8334 14.9052 10.8334 13.3335 10.8334L10.0002 18.3334L6.66683 10.8334C5.09516 10.8334 4.31016 10.8334 3.82183 11.3217C3.3335 11.81 3.3335 12.595 3.3335 14.1667V18.3334"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6668 18.3334V14.1667C16.6668 12.595 16.6668 11.81 16.1785 11.3217C15.6902 10.8334 14.9052 10.8334 13.3335 10.8334L10.0002 18.3334L6.66683 10.8334C5.09516 10.8334 4.31016 10.8334 3.82183 11.3217C3.3335 11.81 3.3335 12.595 3.3335 14.1667V18.3334"
        stroke="url(#paint0_linear_4001_9758)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0002 12.5L9.5835 15.8334L10.0002 17.0834L10.4168 15.8334L10.0002 12.5ZM10.0002 12.5L9.16683 10.8334H10.8335L10.0002 12.5ZM12.9168 5.41669V4.58335C12.9168 4.20033 12.8414 3.82106 12.6948 3.46719C12.5482 3.11333 12.3334 2.7918 12.0626 2.52096C11.7917 2.25012 11.4702 2.03528 11.1163 1.88871C10.7625 1.74213 10.3832 1.66669 10.0002 1.66669C9.61714 1.66669 9.23787 1.74213 8.884 1.88871C8.53014 2.03528 8.20861 2.25012 7.93777 2.52096C7.66693 2.7918 7.45209 3.11333 7.30551 3.46719C7.15894 3.82106 7.0835 4.20033 7.0835 4.58335V5.41669C7.0835 5.79971 7.15894 6.17898 7.30551 6.53285C7.45209 6.88671 7.66693 7.20824 7.93777 7.47908C8.20861 7.74992 8.53014 7.96476 8.884 8.11134C9.23787 8.25791 9.61714 8.33335 10.0002 8.33335C10.3832 8.33335 10.7625 8.25791 11.1163 8.11134C11.4702 7.96476 11.7917 7.74992 12.0626 7.47908C12.3334 7.20824 12.5482 6.88671 12.6948 6.53285C12.8414 6.17898 12.9168 5.79971 12.9168 5.41669Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0002 12.5L9.5835 15.8334L10.0002 17.0834L10.4168 15.8334L10.0002 12.5ZM10.0002 12.5L9.16683 10.8334H10.8335L10.0002 12.5ZM12.9168 5.41669V4.58335C12.9168 4.20033 12.8414 3.82106 12.6948 3.46719C12.5482 3.11333 12.3334 2.7918 12.0626 2.52096C11.7917 2.25012 11.4702 2.03528 11.1163 1.88871C10.7625 1.74213 10.3832 1.66669 10.0002 1.66669C9.61714 1.66669 9.23787 1.74213 8.884 1.88871C8.53014 2.03528 8.20861 2.25012 7.93777 2.52096C7.66693 2.7918 7.45209 3.11333 7.30551 3.46719C7.15894 3.82106 7.0835 4.20033 7.0835 4.58335V5.41669C7.0835 5.79971 7.15894 6.17898 7.30551 6.53285C7.45209 6.88671 7.66693 7.20824 7.93777 7.47908C8.20861 7.74992 8.53014 7.96476 8.884 8.11134C9.23787 8.25791 9.61714 8.33335 10.0002 8.33335C10.3832 8.33335 10.7625 8.25791 11.1163 8.11134C11.4702 7.96476 11.7917 7.74992 12.0626 7.47908C12.3334 7.20824 12.5482 6.88671 12.6948 6.53285C12.8414 6.17898 12.9168 5.79971 12.9168 5.41669Z"
        stroke="url(#paint1_linear_4001_9758)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4001_9758"
          x1="-11.9606"
          y1="-25.4948"
          x2="1.69113"
          y2="-27.7251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4001_9758"
          x1="0.39232"
          y1="-73.0078"
          x2="6.51697"
          y2="-73.2208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
