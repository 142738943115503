import { useTheme } from 'styled-components/macro'

export const WalletsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 6.66667C15.8056 6.39667 15.8333 6.1189 15.8333 5.83334C15.8327 5.2512 15.7102 4.67565 15.4735 4.14378C15.2369 3.61191 14.8914 3.13552 14.4594 2.74534C14.0274 2.35516 13.5184 2.05983 12.9653 1.8784C12.4121 1.69696 11.8271 1.63345 11.2479 1.69196C10.6687 1.75046 10.1082 1.92968 9.60254 2.21807C9.09686 2.50645 8.65721 2.8976 8.31195 3.3663C7.96669 3.835 7.72347 4.37084 7.59797 4.93929C7.47248 5.50774 7.46749 6.09618 7.58333 6.66667M13.3333 11.6667C13.3333 11.9982 13.465 12.3161 13.6994 12.5506C13.9339 12.785 14.2518 12.9167 14.5833 12.9167C14.9149 12.9167 15.2328 12.785 15.4672 12.5506C15.7016 12.3161 15.8333 11.9982 15.8333 11.6667C15.8333 11.3352 15.7016 11.0172 15.4672 10.7828C15.2328 10.5484 14.9149 10.4167 14.5833 10.4167C14.2518 10.4167 13.9339 10.5484 13.6994 10.7828C13.465 11.0172 13.3333 11.3352 13.3333 11.6667Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.75 6.66667C15.8056 6.39667 15.8333 6.1189 15.8333 5.83334C15.8327 5.2512 15.7102 4.67565 15.4735 4.14378C15.2369 3.61191 14.8914 3.13552 14.4594 2.74534C14.0274 2.35516 13.5184 2.05983 12.9653 1.8784C12.4121 1.69696 11.8271 1.63345 11.2479 1.69196C10.6687 1.75046 10.1082 1.92968 9.60254 2.21807C9.09686 2.50645 8.65721 2.8976 8.31195 3.3663C7.96669 3.835 7.72347 4.37084 7.59797 4.93929C7.47248 5.50774 7.46749 6.09618 7.58333 6.66667M13.3333 11.6667C13.3333 11.9982 13.465 12.3161 13.6994 12.5506C13.9339 12.785 14.2518 12.9167 14.5833 12.9167C14.9149 12.9167 15.2328 12.785 15.4672 12.5506C15.7016 12.3161 15.8333 11.9982 15.8333 11.6667C15.8333 11.3352 15.7016 11.0172 15.4672 10.7828C15.2328 10.5484 14.9149 10.4167 14.5833 10.4167C14.2518 10.4167 13.9339 10.5484 13.6994 10.7828C13.465 11.0172 13.3333 11.3352 13.3333 11.6667Z"
        stroke="url(#paint0_linear_2418_9929)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83341 6.66084H13.3334C15.6901 6.66084 16.8692 6.66084 17.6009 7.39417C18.3334 8.12584 18.3334 9.305 18.3334 11.6633V13.3308C18.3334 15.6892 18.3334 16.8683 17.6009 17.6008C16.8692 18.3333 15.6901 18.3333 13.3334 18.3333H8.33341C5.19091 18.3333 3.61925 18.3333 2.64341 17.3567C1.66758 16.38 1.66675 14.8075 1.66675 11.6633V9.99667C1.66675 6.85167 1.66675 5.28 2.64341 4.3025C3.42925 3.51667 4.60008 3.36334 6.66675 3.33334H8.33341"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83341 6.66084H13.3334C15.6901 6.66084 16.8692 6.66084 17.6009 7.39417C18.3334 8.12584 18.3334 9.305 18.3334 11.6633V13.3308C18.3334 15.6892 18.3334 16.8683 17.6009 17.6008C16.8692 18.3333 15.6901 18.3333 13.3334 18.3333H8.33341C5.19091 18.3333 3.61925 18.3333 2.64341 17.3567C1.66758 16.38 1.66675 14.8075 1.66675 11.6633V9.99667C1.66675 6.85167 1.66675 5.28 2.64341 4.3025C3.42925 3.51667 4.60008 3.36334 6.66675 3.33334H8.33341"
        stroke="url(#paint1_linear_2418_9929)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9929"
          x1="-2.05882"
          y1="-52.801"
          x2="6.66082"
          y2="-53.3948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2418_9929"
          x1="-17.4509"
          y1="-69.3229"
          x2="-0.111523"
          y2="-71.0934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
