import { useTranslation } from 'react-i18next'
import { PROJECT_NAME } from '../../../config/serverConstants'
import {
  ContainerHead,
  FlexRow,
  GradientText,
  TextContainerBlur,
} from '../../../style/projectComponents'

import styled from 'styled-components/macro'
const CardsWrapper = styled(FlexRow)`
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
`
const Card = styled.div`
  height: 233px;
  width: 333px;
  background: #20202080;
  backdrop-filter: blur(10px);
  border-radius: 9px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.29px;
  text-align: center;

  &:nth-child(1) {
    & img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &:nth-child(2) {
    & img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &:nth-child(3) {
    & img {
      position: absolute;
      left: -10px;
      top: 0;
    }
  }
  &:nth-child(4) {
    & img {
      position: absolute;
      left: 0px;
      top: 0;
    }
  }
`
const SmallText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: center;
`

export const NeverMiss = () => {
  const { t } = useTranslation()
  return (
    <TextContainerBlur>
      <ContainerHead>
        {t('auth.presentation.neverMissTitle')}{' '}
        <GradientText>
          {PROJECT_NAME === 'Time' ? t('auth.presentation.title') : t('auth.presentation.defTitle')}
        </GradientText>
      </ContainerHead>
      <CardsWrapper>
        <Card>
          <img src="./images/never-card-1.png" alt="" />
          <div>{t('auth.presentation.neverMissCard1')}</div>
        </Card>
        <Card>
          <img src="./images/never-card-2.png" alt="" />
          <div>{t('auth.presentation.neverMissCard2')}</div>
          <SmallText>{t('auth.presentation.neverMissCard2_2')}</SmallText>
        </Card>
        <Card>
          <img src="./images/never-card-3.png" alt="" />
          <div>{t('auth.presentation.neverMissCard3')}</div>
        </Card>
        <Card>
          <img src="./images/never-card-4.png" alt="" />
          <div>{t('auth.presentation.neverMissCard4')}</div>
        </Card>
      </CardsWrapper>
    </TextContainerBlur>
  )
}
