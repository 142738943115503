import { FlexRowBetween, InputTitle } from '../../style/projectComponents'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore'
import ProfileStorage from '../../storage/ProfileStorage/ProfileStorage'

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 16px;
  border-radius: 16px;
  width: 100%;
  max-width: 556px;
  background: ${({ theme }) => theme.colors.gray050};
  margin-bottom: 20px;
  /* height: 124px; */

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 920px) {
    max-width: 100%;
  }
`

const RefCodeButton = styled(FlexRowBetween)`
  padding: 16px;
  font-size: 15px;
  background: ${({ theme }) => theme.colors.inputBackground};
  border-radius: 8px;
  cursor: pointer;
  margin-top: 12px;
  width: 100%;
  align-items: center;

  img {
    width: 18px;
    object-fit: cover;
    margin: 0;
    padding: 4px;
    filter: ${({ theme }) => theme.colors.black === '#fff' && 'invert(1)'};
  }
`

export const ReferralLinkBlock = () => {
  const { t } = useTranslation()
  const GlobalModalStorage = useContext(GlobalModalStore)
  const ProfileStore = useContext(ProfileStorage)

  const profile = ProfileStore?.getProfile()
  const refCode = profile?.referralCode

  const refLink = `https://time.investments/register?ref=${refCode}`
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(refLink)
      GlobalModalStorage.makeVisible(true, t('common.copySuccess'))
    } catch (err) {
      GlobalModalStorage.makeVisible(false, t('common.copyFailed'))
    }
  }

  return (
    <Block>
      <InputTitle style={{ marginTop: 0, width: '100%', textAlign: 'left' }}>
        {t('auth.referralLink')}
      </InputTitle>
      <RefCodeButton onClick={copyToClipboard}>
        <img src="/images/interface/copy.svg" alt="" />
        <p>{refCode}</p>
      </RefCodeButton>
    </Block>
  )
}
