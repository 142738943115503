import { useTheme } from 'styled-components/macro'

export const SettingsSvgIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9163 10C12.9163 10.3831 12.8409 10.7623 12.6943 11.1162C12.5477 11.4701 12.3329 11.7916 12.0621 12.0624C11.7912 12.3333 11.4697 12.5481 11.1158 12.6947C10.762 12.8413 10.3827 12.9167 9.99967 12.9167C9.61665 12.9167 9.23738 12.8413 8.88351 12.6947C8.52965 12.5481 8.20812 12.3333 7.93728 12.0624C7.66644 11.7916 7.4516 11.4701 7.30503 11.1162C7.15845 10.7623 7.08301 10.3831 7.08301 10C7.08301 9.22649 7.3903 8.48463 7.93728 7.93765C8.48426 7.39066 9.22613 7.08337 9.99967 7.08337C10.7732 7.08337 11.5151 7.39066 12.0621 7.93765C12.6091 8.48463 12.9163 9.22649 12.9163 10Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9163 10C12.9163 10.3831 12.8409 10.7623 12.6943 11.1162C12.5477 11.4701 12.3329 11.7916 12.0621 12.0624C11.7912 12.3333 11.4697 12.5481 11.1158 12.6947C10.762 12.8413 10.3827 12.9167 9.99967 12.9167C9.61665 12.9167 9.23738 12.8413 8.88351 12.6947C8.52965 12.5481 8.20812 12.3333 7.93728 12.0624C7.66644 11.7916 7.4516 11.4701 7.30503 11.1162C7.15845 10.7623 7.08301 10.3831 7.08301 10C7.08301 9.22649 7.3903 8.48463 7.93728 7.93765C8.48426 7.39066 9.22613 7.08337 9.99967 7.08337C10.7732 7.08337 11.5151 7.39066 12.0621 7.93765C12.6091 8.48463 12.9163 9.22649 12.9163 10Z"
        stroke="url(#paint0_linear_4001_9750)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.509 11.7475C17.944 11.63 18.1615 11.5709 18.2473 11.4592C18.3332 11.3467 18.3332 11.1667 18.3332 10.8059V9.19419C18.3332 8.83336 18.3332 8.65253 18.2473 8.54086C18.1615 8.42919 17.944 8.37003 17.509 8.25336C15.884 7.81503 14.8665 6.11586 15.2857 4.50086C15.4015 4.05669 15.459 3.83419 15.404 3.70419C15.349 3.57419 15.1907 3.48419 14.8748 3.30503L13.4373 2.48836C13.1273 2.31336 12.9723 2.22503 12.8332 2.24336C12.694 2.26169 12.5365 2.41836 12.2223 2.73253C11.0065 3.94503 8.99484 3.94503 7.77817 2.73253C7.464 2.41919 7.30734 2.26253 7.16817 2.24336C7.029 2.22503 6.874 2.31253 6.564 2.48919L5.1265 3.30503C4.80984 3.48419 4.6515 3.57419 4.59734 3.70503C4.54234 3.83419 4.59984 4.05669 4.71484 4.50086C5.134 6.11586 4.1165 7.81503 2.49067 8.25336C2.05567 8.37003 1.83817 8.42836 1.75234 8.54086C1.6665 8.65336 1.6665 8.83336 1.6665 9.19419V10.8059C1.6665 11.1667 1.6665 11.3475 1.75234 11.4592C1.83817 11.5709 2.05567 11.63 2.49067 11.7475C4.11567 12.1859 5.13317 13.885 4.714 15.4992C4.59817 15.9434 4.54067 16.1659 4.59567 16.2959C4.65067 16.4259 4.809 16.5159 5.12484 16.6959L6.56234 17.5109C6.87234 17.6875 7.02734 17.775 7.1665 17.7567C7.30567 17.7384 7.46317 17.5817 7.77734 17.2675C8.994 16.0534 11.0073 16.0534 12.224 17.2675C12.5382 17.5809 12.6948 17.7375 12.834 17.7567C12.9732 17.775 13.1282 17.6875 13.439 17.5109L14.8757 16.695C15.1923 16.5159 15.3507 16.4259 15.4048 16.295C15.459 16.1642 15.4023 15.9434 15.2873 15.4992C14.8673 13.885 15.884 12.1859 17.509 11.7475Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.509 11.7475C17.944 11.63 18.1615 11.5709 18.2473 11.4592C18.3332 11.3467 18.3332 11.1667 18.3332 10.8059V9.19419C18.3332 8.83336 18.3332 8.65253 18.2473 8.54086C18.1615 8.42919 17.944 8.37003 17.509 8.25336C15.884 7.81503 14.8665 6.11586 15.2857 4.50086C15.4015 4.05669 15.459 3.83419 15.404 3.70419C15.349 3.57419 15.1907 3.48419 14.8748 3.30503L13.4373 2.48836C13.1273 2.31336 12.9723 2.22503 12.8332 2.24336C12.694 2.26169 12.5365 2.41836 12.2223 2.73253C11.0065 3.94503 8.99484 3.94503 7.77817 2.73253C7.464 2.41919 7.30734 2.26253 7.16817 2.24336C7.029 2.22503 6.874 2.31253 6.564 2.48919L5.1265 3.30503C4.80984 3.48419 4.6515 3.57419 4.59734 3.70503C4.54234 3.83419 4.59984 4.05669 4.71484 4.50086C5.134 6.11586 4.1165 7.81503 2.49067 8.25336C2.05567 8.37003 1.83817 8.42836 1.75234 8.54086C1.6665 8.65336 1.6665 8.83336 1.6665 9.19419V10.8059C1.6665 11.1667 1.6665 11.3475 1.75234 11.4592C1.83817 11.5709 2.05567 11.63 2.49067 11.7475C4.11567 12.1859 5.13317 13.885 4.714 15.4992C4.59817 15.9434 4.54067 16.1659 4.59567 16.2959C4.65067 16.4259 4.809 16.5159 5.12484 16.6959L6.56234 17.5109C6.87234 17.6875 7.02734 17.775 7.1665 17.7567C7.30567 17.7384 7.46317 17.5817 7.77734 17.2675C8.994 16.0534 11.0073 16.0534 12.224 17.2675C12.5382 17.5809 12.6948 17.7375 12.834 17.7567C12.9732 17.775 13.1282 17.6875 13.439 17.5109L14.8757 16.695C15.1923 16.5159 15.3507 16.4259 15.4048 16.295C15.459 16.1642 15.4023 15.9434 15.2873 15.4992C14.8673 13.885 15.884 12.1859 17.509 11.7475Z"
        stroke="url(#paint1_linear_4001_9750)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4001_9750"
          x1="0.391831"
          y1="-21.1718"
          x2="6.47253"
          y2="-21.7306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4001_9750"
          x1="-17.4511"
          y1="-72.9249"
          x2="-0.100011"
          y2="-74.6375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
