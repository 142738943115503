import { useTranslation } from 'react-i18next'
import { PROJECT_NAME } from '../../../config/serverConstants'
import {
  ContainerHead,
  Description,
  DescriptionWrapper,
  GradientText,
  TextContainerBlur,
} from '../../../style/projectComponents'
import { HeaderImgDef } from '../../global/HeaderComponent/images/HeaderImgDef'
import { HeaderImgTime } from '../../global/HeaderComponent/images/HeaderImgTime'
import styled from 'styled-components/macro'

const Images = {
  Def: HeaderImgDef,
  Time: HeaderImgTime,
}
const MobileView = styled.div<{ mobile?: boolean }>`
  width: 100%;
  display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  @media screen and (max-width: 1024px) {
    display: ${({ mobile }) => (mobile ? 'block' : 'none')};
    padding: 0 10px;
  }
`

export const Welcome = ({ mobile }: { mobile?: boolean }) => {
  const { t } = useTranslation()
  return (
    <MobileView mobile={mobile}>
      <TextContainerBlur>
        <img src={Images[PROJECT_NAME].logo} alt="logo" height={40} />
        <ContainerHead>
          {t('auth.presentation.welcome')}{' '}
          <GradientText>
            {PROJECT_NAME === 'Time'
              ? t('auth.presentation.title')
              : t('auth.presentation.defTitle')}
          </GradientText>
        </ContainerHead>
        <DescriptionWrapper>
          <Description>
            {PROJECT_NAME === 'Time'
              ? t('auth.presentation.description')
              : t('auth.presentation.defDescription')}
          </Description>
          <br />
          <Description>{t('auth.presentation.description2')}</Description>
        </DescriptionWrapper>
      </TextContainerBlur>
    </MobileView>
  )
}
