import { useTheme } from 'styled-components/macro'

export const UserListIcon = ({ isActive }) => {
  const theme = useTheme()
  const firstColor = isActive ? '#FF8A00' : theme.colors.black
  const secondColor = isActive ? '#D449F7' : theme.colors.black

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.99175 15.1475C2.86706 15.0613 2.75047 14.964 2.64341 14.8567C1.66675 13.8808 1.66675 12.3092 1.66675 9.16667C1.66675 6.02417 1.66675 4.4525 2.64341 3.47667C3.62008 2.50083 5.19091 2.5 8.33341 2.5H11.6667C14.8092 2.5 16.3809 2.5 17.3567 3.47667C18.3326 4.45333 18.3334 6.02417 18.3334 9.16667C18.3334 12.3092 18.3334 13.8808 17.3567 14.8567C17.249 14.965 17.1329 15.0619 17.0084 15.1475"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.99175 15.1475C2.86706 15.0613 2.75047 14.964 2.64341 14.8567C1.66675 13.8808 1.66675 12.3092 1.66675 9.16667C1.66675 6.02417 1.66675 4.4525 2.64341 3.47667C3.62008 2.50083 5.19091 2.5 8.33341 2.5H11.6667C14.8092 2.5 16.3809 2.5 17.3567 3.47667C18.3326 4.45333 18.3334 6.02417 18.3334 9.16667C18.3334 12.3092 18.3334 13.8808 17.3567 14.8567C17.249 14.965 17.1329 15.0619 17.0084 15.1475"
        stroke="url(#paint0_linear_2418_9916)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.15465 13.6883C4.43465 14.1275 2.54548 15.0233 3.69632 16.1442C4.25798 16.6917 4.88298 17.0833 5.67132 17.0833H10.1621C10.9488 17.0833 11.5746 16.6917 12.1371 16.1442C13.2871 15.0233 11.398 14.1275 10.678 13.6883C9.84535 13.1833 8.89015 12.9162 7.91632 12.9162C6.94248 12.9162 5.98728 13.1833 5.15465 13.6883ZM9.99965 8.75C9.99965 9.30253 9.78016 9.83244 9.38945 10.2231C8.99875 10.6138 8.46885 10.8333 7.91632 10.8333C7.36378 10.8333 6.83388 10.6138 6.44318 10.2231C6.05248 9.83244 5.83298 9.30253 5.83298 8.75C5.83298 8.19746 6.05248 7.66756 6.44318 7.27686C6.83388 6.88616 7.36378 6.66666 7.91632 6.66666C8.46885 6.66666 8.99875 6.88616 9.38945 7.27686C9.78016 7.66756 9.99965 8.19746 9.99965 8.75Z"
        stroke={secondColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.15465 13.6883C4.43465 14.1275 2.54548 15.0233 3.69632 16.1442C4.25798 16.6917 4.88298 17.0833 5.67132 17.0833H10.1621C10.9488 17.0833 11.5746 16.6917 12.1371 16.1442C13.2871 15.0233 11.398 14.1275 10.678 13.6883C9.84535 13.1833 8.89015 12.9162 7.91632 12.9162C6.94248 12.9162 5.98728 13.1833 5.15465 13.6883ZM9.99965 8.75C9.99965 9.30253 9.78016 9.83244 9.38945 10.2231C8.99875 10.6138 8.46885 10.8333 7.91632 10.8333C7.36378 10.8333 6.83388 10.6138 6.44318 10.2231C6.05248 9.83244 5.83298 9.30253 5.83298 8.75C5.83298 8.19746 6.05248 7.66756 6.44318 7.27686C6.83388 6.88616 7.36378 6.66666 7.91632 6.66666C8.46885 6.66666 8.99875 6.88616 9.38945 7.27686C9.78016 7.66756 9.99965 8.19746 9.99965 8.75Z"
        stroke="url(#paint1_linear_2418_9916)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.25 5.41666H15.8333M13.75 9.16666H15.8333M12.9167 12.9167H15.8333"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.25 5.41666H15.8333M13.75 9.16666H15.8333M12.9167 12.9167H15.8333"
        stroke="url(#paint2_linear_2418_9916)"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2418_9916"
          x1="-17.4509"
          y1="-58.7613"
          x2="-0.183968"
          y2="-60.8524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2418_9916"
          x1="5.99265"
          y1="-30.9115"
          x2="10.7955"
          y2="-31.1812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={firstColor} />
          <stop offset="1" stop-color={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
