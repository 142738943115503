import styled from 'styled-components/macro'
import { FlexColumn, StyledBg } from '../../style/projectComponents'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { OperationsComponent } from '../../components/DashboardComponents/OperationsComponent'
import { DataEmptyComponent } from '../../components/global/DataEmptyComponent'
import { PaginationComponent } from '../../components/global/PaginationComponent'
import { generatePagesArray } from '../../utils/generatePagesArray'
import UserApi from '../../service/user-api/UserApi'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PageWrapper from '../../components/global/PageWrapper'

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
`

export const AdminUsers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [usersPageId, setUsersPageId] = useState(0)
  const itemsPerPage = 20

  const { data: usersData, isLoading: usersDataLoading } = useQuery(
    ['users_list', usersPageId],
    () =>
      UserApi.getAllUsersList({
        skip: usersPageId * itemsPerPage,
        take: itemsPerPage,
      })
  )

  const totalUsersPages = useMemo(
    () => (usersData?.totalCount ? Math.ceil(usersData?.totalCount / itemsPerPage) : 1),
    [usersData?.totalCount, itemsPerPage]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleOpenUser = (data: any) => {
    navigate(`/admin/${data.id}`)
  }
  const handleUsers = () => {
    const res = usersData?.items?.map(it => ({
      ...it,
      balance: it.wallets.find(it => it.currency === 'USDT')?.balance ?? 0,
    }))
    return res
  }

  return (
    <PageWrapper>
      <StyledBg>
        <OperationsWrapper>
          <ChartTitle>{t('adminAnalytics.users')}</ChartTitle>
          {!usersDataLoading && usersData?.items?.length > 0 ? (
            <OperationsComponent
              keys={['email', 'firstName', 'lastName', 'balance']}
              onClickItem={handleOpenUser}
              operations={handleUsers()}
              withTopBorder
            />
          ) : (
            <DataEmptyComponent />
          )}
          <PaginationComponent
            maxPages={totalUsersPages}
            pageId={usersPageId}
            pagesNum={generatePagesArray(totalUsersPages, usersPageId, 100)}
            setPageId={setUsersPageId}
          />
        </OperationsWrapper>
      </StyledBg>
    </PageWrapper>
  )
}
