import React, { ComponentPropsWithoutRef, useState } from 'react'
import { StyledInput } from '../../style/projectComponents'
import styled from 'styled-components/macro'

type ValidationInputComponentProps = {
  setValueToMax: any
  value?: string
  onChange: any
  isError?: any
  label: string
  ref?: any
}

const StyledMaxButton = styled.div`
  position: absolute;
  right: 20px;
  margin-top: 21px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  background: none;
  cursor: pointer;
  color: #68dbb3;
  user-select: none;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const CustomStyledInput = styled(StyledInput)`
  width: 100%;
  position: relative;
  font-family: sans-serif;

  @media (max-width: 480px) {
    /* max-width: 280px; */
  }
`
export const ValidationInputComponentMax: React.FC<
  ValidationInputComponentProps & ComponentPropsWithoutRef<'input'>
> = props => {
  const { value, onChange, isError, label, setValueToMax, ...other } = props
  const [isFocused, setIsFocused] = useState(false)

  return (
    <>
      <CustomStyledInput
        {...other}
        value={value ? value : ''}
        placeholder={isFocused ? '' : label}
        onChange={onChange}
        isFailed={isError}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
      />
      <StyledMaxButton onClick={setValueToMax}>MAX</StyledMaxButton>
    </>
  )
}
