import styled from 'styled-components/macro'
import { FlexColumn } from '../../style/projectComponents'
import { useGlobalMenuSizeState } from '../../store/MenuSizeContext'

const Page = styled(FlexColumn)<{ menuSize: number }>`
  width: calc(100vw - ${({ menuSize }) => menuSize}px);
  flex-grow: 1;
  overflow: scroll;
  justify-content: start;
  @media (max-width: 800px) {
    width: 100vw;
  }
`

const PageWrapper = ({ children }) => {
  const { menuSize } = useGlobalMenuSizeState()

  return <Page menuSize={menuSize}>{children}</Page>
}

export default PageWrapper
